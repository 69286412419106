<template>
    <div>
      <form id="reservationForm" @submit.prevent="submitForm" class="w-full relative my-[20px] overflow-auto h-[calc(100%-60px)]">
        <div v-for="(field, index) in fields" :key="index" class="my-[10px] w-full relative">
          <label :for="field.id" class="text-xs font-bold text-gray-500">{{ field.label }}</label>
          <input :type="field.type" v-model="reservation[field.model]" :id="field.id" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
        </div>
        <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded">Ajouter la réservation</button>
      </form>
      <div v-if="qrCodePath" class="mt-4">
        <img :src="qrCodePath" alt="QR Code">
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import axios from 'axios';
  
  const reservation = ref({
    nom: '',
    prénoms: '',
    email: '',
    numero: '',
    nombreBagage: '',
    moyenPaiement: '',
    numeroPaiement: '',
    codeService: '',
    idCompagnie: 'votre_idCompagnie', // Remplacez par la méthode d'obtention de l'idCompte de l'utilisateur
    date: '',
    depart: '',
    destination: '',
    confort: '',
    nombrePersonne: ''
  });
  
  const qrCodePath = ref('');
  
  const fields = [
    { id: 'nom', label: 'Nom', model: 'nom', type: 'text' },
    { id: 'prénoms', label: 'Prénoms', model: 'prénoms', type: 'text' },
    { id: 'email', label: 'Email', model: 'email', type: 'email' },
    { id: 'numero', label: 'Numéro', model: 'numero', type: 'number' },
    { id: 'nombreBagage', label: 'Nombre de Bagages', model: 'nombreBagage', type: 'number' },
    { id: 'moyenPaiement', label: 'Moyen de Paiement', model: 'moyenPaiement', type: 'text' },
    { id: 'numeroPaiement', label: 'Numéro de Paiement', model: 'numeroPaiement', type: 'number' },
    { id: 'codeService', label: 'Code Service', model: 'codeService', type: 'text' },
    { id: 'date', label: 'Date', model: 'date', type: 'text' },
    { id: 'depart', label: 'Départ', model: 'depart', type: 'text' },
    { id: 'destination', label: 'Destination', model: 'destination', type: 'text' },
    { id: 'confort', label: 'Confort', model: 'confort', type: 'text' },
    { id: 'nombrePersonne', label: 'Nombre de Personnes', model: 'nombrePersonne', type: 'number' }
  ];
  
  const submitForm = async () => {
    try {
      const response = await axios.post('/api/reservations', reservation.value);
      qrCodePath.value = response.data.qrCodePath;
      alert('Réservation ajoutée avec succès');
    } catch (error) {
      alert('Erreur lors de l\'ajout de la réservation');
    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles supplémentaires si nécessaire */
  </style>
  