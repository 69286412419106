<template>
    <div class="flex flex-col z-[500] justify-center items-center w-[350px] max-w-[90%] left-[50%] translate-x-[-50%] fixed top-[80px] ">
        <div v-if="SuccessMessage" class="mt-[10px] z-[500] bg-green-600 shadow-4xl text-white flex flex-col justify-center items-center font-bold text-center p-[20px] w-full rounded-[10px]">
            {{SuccessMessage}} 
        </div>
        <div v-if="ErrorMessage" class="mt-[10px] z-[500] bg-red-600 shadow-4xl text-white flex flex-col justify-center items-center font-bold text-center p-[20px] w-full rounded-[10px]">
            {{ErrorMessage}}
        </div>
    </div>
    <footer class="w-full relative flex flex-col justify-center items-center bg-white shadow-4xl">
        <div class="w-full bg-blue-50 flex justify-center items-center relative">
            <div class="container max-w-[1024px] px-[20px] flex w-full relative justify-center items-center">
                <div class="w-full py-[20px] md:py-[50px] rounded-[10px] md:rounded-[20px] flex flex-col md:flex-row justify-between items-center">
                    <div>
                        <h1 class="text-sm md:text-2xl font-bold text-gray-800">Nouveautés & Actualités</h1>
                        <p class="text-xs my-[10px] text-gray-500 font-semibold max-w-[90%] text-left">
                            Laissez nous dès maintenant votre email pour être au courant des offres, nouveautés et actualités de la plateforme
                        </p>
                    </div>
                    <form @submit.prevent="subscribe" class="mt-[20px] w-full max-w-[600px] relative">
                        <button type="submit" class="text-sm font-bold text-white bg-gray-800 py-[5px] px-[15px] absolute right-[5px] rounded-[5px] top-[6px]">S'abonner</button>
                        <input type="text" v-model="email" required class="w-full rounded-[5px] p-[10px] outline-none border border-gray-300 text-sm bg-gray-100 pr-[125px]" placeholder="Entrez votre mail">
                    </form>
                </div>
            </div>
        </div>
        <div class="w-full h-auto z-10 flex justify-center items-center">
            <div class="container max-w-[1024px] md:h-[250px] h-[300px] w-full flex items-start relative px-[20px] md:py-[50px] py-[25px]">
                <div class="flex md:flex-row flex-col md:justify-between md:items-end justify-center items-center w-full relative">
                    <div class="w-full">
                        <div class="logo flex text-sm font-bold text-black">
                            <i class="fa-solid fa-bus-simple text-sm text-gray-600 mr-[5px]"></i>
                            <h1>Bénin Ease Travel</h1>
                        </div>
                        <div class="hidden md:flex">
                            <ul class="flex md:justify-start justify-center items-center flex-wrap md:px-0">
                                <li @click="goToHomePage()" class="cursor-pointer mr-[25px] hover:underline mt-[25px] text-sm text-gray-600 font-semibold">Accueil</li>
                                <li @click="goToAboutPage()" class="cursor-pointer mr-[25px] hover:underline mt-[25px] text-sm text-gray-600 font-semibold">A propos</li>
                                <li @click="goToReservationPage()" class="cursor-pointer mr-[25px] hover:underline mt-[25px] text-sm text-gray-600 font-semibold">Reservation</li>
                                <li @click="goToContactPage()" class="cursor-pointer mr-[25px] hover:underline mt-[25px] text-sm text-gray-600 font-semibold">Contact</li>
                            </ul>
                        </div>
                    </div>
                    <div class="flex w-full justify-between my-[25px] items-center md:hidden">
                        <div class="w-[calc(100%/4-10px)] p-[10px] rounded-[5px] border border-gray-200 bg-gray-100 text-gray-800 flex justify-center items-center cursor-pointer my-[10px]"><i class="fa-brands fa-facebook"></i></div>
                        <div class="w-[calc(100%/4-10px)] p-[10px] rounded-[5px] border border-gray-200 bg-gray-100 text-gray-800 flex justify-center items-center cursor-pointer my-[10px]"><i class="fa-brands fa-twitter"></i></div>
                        <div class="w-[calc(100%/4-10px)] p-[10px] rounded-[5px] border border-gray-200 bg-gray-100 text-gray-800 flex justify-center items-center cursor-pointer my-[10px]"><i class="fa-brands fa-instagram"></i></div>
                        <div class="w-[calc(100%/4-10px)] p-[10px] rounded-[5px] border border-gray-200 bg-gray-100 text-gray-800 flex justify-center items-center cursor-pointer my-[10px]"><i class="fa-brands fa-tiktok"></i></div>
                    </div>
                    <div class="relative w-full md:w-auto flex md:justify-center justify-between items-center">
                        <button class="p-[10px] rounded-[5px] md:w-auto w-[48%] border border-gray-800 font-bold text-sm text-gray-800 min-w-[115px] md:mx-[15px]">Se connecter</button>
                        <button class="p-[10px] rounded-[5px] md:w-auto w-[48%] border border-gray-800 font-bold text-sm text-white bg-gray-800">S'inscrire</button>
                    </div>
                </div>
                <div class="w-[calc(100%-40px)] absolute md:py-[25px] py-[25px] border-t border-gray-300 flex justify-between items-center bottom-0">
                    <h1 class="text-xs font-semibold text-gray-600">&copy; 2024 BET, Tous droits réservés.</h1>
                    <div class="flex items-center justify-center">
                        <button class="text-xs font-semibold text-gray-600">Terms</button>
                        <button class="text-xs font-semibold text-gray-600 mx-[15px]">Privacy</button>
                        <button class="text-xs font-semibold text-gray-600">Cookies</button>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

const router = useRouter()

const ErrorMessage = ref('')
const SuccessMessage = ref('')
const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      SuccessMessage.value = ''; 
    }, 3000);
};
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      ErrorMessage.value = ''; 
    }, 3000);
};

function goToHomePage() {
    router.push("/")
}
function goToReservationPage() {
    router.push("/reservation")
}
function goToContactPage() {
    router.push("/contact")
}
function goToAboutPage() {
    router.push("/about")
}

const email = ref('');
const message = ref('');

const subscribe = async () => {
  try {
    const response = await axios.post('/api/newsletter/subscribe', { email: email.value });
    SuccessMessage.value = response.data.message;
    hideSuccessMessageAfterDelay()
  } catch (error) {
    if (error.response && error.response.status === 400) {
      SuccessMessage.value = 'Cet email est déjà abonné';
      hideSuccessMessageAfterDelay()
    } else {
      ErrorMessage.value = 'Erreur lors de l\'inscription à la newsletter';
      hideErrorMessageAfterDelay()
    }
  }
};
</script>

<style>

</style>