import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ReservationView from '@/views/ReservationView.vue'
import PartenairsView from '@/views/PartenairsView.vue'
import ContactView from '@/views/ContactView.vue'
import AboutView from '@/views/AboutView.vue'
import LoginView from '@/views/LoginView.vue'
import ConfirmReservation from '@/views/ConfirmReservation.vue'
import ExerciceView from '@/views/ExerciceView.vue'
import TestView from '@/views/TestView.vue'
import ScannerQR from '@/views/ScannerQR.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      description: "Réservez vos trajets en bus facilement avec Bénin Ease Travel. Découvrez nos partenaires, bénéficiez de réductions exclusives et voyagez en toute tranquillité.",
      keywords: "réservation de bus, trajets en bus, Bénin Ease Travel, partenaires de bus, réductions bus",
      title: "Bénin Ease Travel - Accueil",
      scrollToTop: true
    }    
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: ReservationView,
    meta: {
      description: "Réservez votre trajet en bus avec Bénin Ease Travel en quelques clics. Choisissez votre trajet, votre siège, et profitez de nos tarifs avantageux.",
      keywords: "réservation de bus, choix de trajet, réservation en ligne, Bénin Ease Travel, sièges de bus, tarifs avantageux",
      title: "Bénin Ease Travel - Réservation de Bus",
      scrollToTop: true
    }    
  },
  {
    path: '/partenairs',
    name: 'partenairs',
    component: PartenairsView,

  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      description: "Contactez Bénin Ease Travel pour toute question ou assistance concernant vos réservations de bus. Nous sommes là pour vous aider.",
      keywords: "contact, assistance, réservation de bus, Bénin Ease Travel, service client, questions",
      title: "Bénin Ease Travel - Contact",
      scrollToTop: true
    }    
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      description: "En savoir plus sur Bénin Ease Travel, notre mission, nos valeurs et nos partenaires de confiance pour vos réservations de bus.",
      keywords: "à propos, Bénin Ease Travel, plateforme de réservation, mission, valeurs, partenaires de bus",
      title: "Bénin Ease Travel - À Propos",
      scrollToTop: true
    }    
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/confirmation/reservation/:id',
    name: 'confirmation',
    component: ConfirmReservation
  },
  {
    path: '/exercice',
    name: 'exercice',
    component: ExerciceView
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },
  {
    path: '/scanne',
    name: 'scanne',
    component: ScannerQR
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    if (to.meta.scrollToTop) {
      return { top: 0 };
    }
  },
})

router.beforeEach((to: any, from: any, next) => {
  document.title = to.meta.title || "Caille d'Or";
  
  const descriptionMeta = document.querySelector('meta[name="description"]');
  if (descriptionMeta && to.meta.description) {
    descriptionMeta.setAttribute('content', to.meta.description);
  }

  const keywordsMeta = document.querySelector('meta[name="keywords"]');
  if (keywordsMeta && to.meta.keywords) {
    keywordsMeta.setAttribute('content', to.meta.keywords);
  }

  next();
});

export default router
