<template>
    <section class="w-full h-screen bg-gray-100 flex justify-center items-center md:p-[20px]">
        <div class="md:rounded-[20px] w-full h-full relative overflow-hidden shadow-4xl flex justify-center items-center ">
            <div class="absolute w-full h-full flex flex-col justify-center items-center">
                <div class="w-full h-[50%] bg-cyan-100"></div>
                <div class="w-full h-[50%] bg-white"></div>
            </div>
            <div class="absolute w-full h-full flex flex-col items-center">
                <div id="header" class="fixed z-50 top-0 left-0 w-full flex justify-center items-center md:h-[100px] h-[70px]">
                    <div class="container flex justify-between items-center px-[20px] w-full relative">
                        <div class="text-gray-800 text-sm font-bold w-full md:w-auto flex justify-between items-center">
                            <h1>
                                Logo
                            </h1>
                            <div class="md:hidden" @click="toggleMenu()">
                                <span v-if="!isOpen"><i class="fa-solid fa-bars text-3xl"></i></span>
                                <span v-else><i class="fa-solid fa-times text-3xl"></i></span>
                            </div>
                        </div>
                        <nav :class="{'translate-x-[100%]': !isOpen, 'translate-x-0': isOpen}" class="fixed md:translate-x-0 md:static duration-300 top-[70px] md:top-0 left-0 w-full md:w-auto h-[calc(100vh-70px)] md:h-auto flex justify-center items-center bg-white md:bg-transparent">
                            <ul class="flex md:flex-row flex-col items-center justify-center">
                                <li class="md:mx-[10px] my-[10px] md:my-0 text-sm font-semibold text-gray-700">Acueil</li>
                                <li class="md:mx-[10px] my-[10px] md:my-0 text-sm font-semibold text-gray-700">Acueil</li>
                                <li class="md:mx-[10px] my-[10px] md:my-0 text-sm font-semibold text-gray-700">Acueil</li>
                                <li class="md:mx-[10px] my-[10px] md:my-0 text-sm font-semibold text-gray-700">Acueil</li>
                                <li class="md:mx-[10px] my-[10px] md:my-0 text-sm font-semibold text-gray-700">Acueil</li>
                                <button class="p-[10px] rounded-[5px] bg-cyan-600 text-white font-bold text-sm md:ml-[10px] mt-[20px] md:mt-0">Contact</button>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="flex justify-center items-center flex-col w-full relative mt-[70px] px-[20px] md:px-0 py-[25px]">
                    <h1 class="text-gray-800 font-bold text-center text-2xl">Get in touch</h1>
                    <p class="w-full max-w-[750px] text-center text-xs mt-[10px]">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident soluta necessitatibus, officiis optio, laboriosam odio incidunt eveniet nostrum illo libero corporis atque sequi veritatis quo repellat consequuntur. Nisi, qui earum?
                    </p>
                    <div class="container max-w-[1024px] w-full h-[350px] rounded-[20px] p-[10px] bg-white shadow-4xl mt-[20px] flex md:flex-row flex-col md:justify-center justify-start items-center">
                        <div id="rond" class="md:w-[30%] w-full relative overflow-hidden md:h-full h-auto bg-teal-600 rounded-[10px] flex flex-col p-[20px]">
                            <h1 class="text-2xl text-gray-100 font-semibold">Contact information</h1>
                            <p class="text-xs text-gray-200 my-[10px]">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, rerum error?
                            </p>
                            <div class="flex w-full justify-start items-center text-gray-100 my-[10px]">
                                <i class="fa-solid fa-phone"></i>
                                <p class="ml-[10px]">+ 229 00000000</p>
                            </div>
                            <div class="flex w-full justify-start items-center text-gray-100 my-[10px]">
                                <i class="fa-solid fa-phone"></i>
                                <p class="ml-[10px]">+ 229 00000000</p>
                            </div>
                            <div class="flex w-full justify-start items-center text-gray-100 my-[10px]">
                                <i class="fa-solid fa-phone"></i>
                                <p class="ml-[10px]">+ 229 00000000</p>
                            </div>
                        </div>
                        <div class="md:w-[70%] w-full md:h-full h-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref } from 'vue'

const isOpen = ref(false)

function toggleMenu() {
    isOpen.value = !isOpen.value
}

</script>

<style>
#rond::after {
    content: '';
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.37), teal);
    position: absolute;
    right: -50px;
    bottom: -50px;
    z-index: 10;
}
</style>