// stores/reservation.ts
import { defineStore } from 'pinia';
import piniaPersistedState from 'pinia-plugin-persistedstate';

// Interface pour les informations de réservation
interface Trajet {
    depart?: string;
    destination?: string;
    heureDepart?: string;
    duree?: number;
    prix?: number;
    prix_reduit?: number;
    places?: number;
    idCompagnie?: string;
    nomCompagnie?: string;
    idTrajet?: string;
}

interface ReservationState {
    trajet: Trajet | null;
    date: string | null;
    membres: number;
    nom: string;
    prenoms: string;
    email: string;
    numero: string;
    bagages: number;
    typePaiement: string;
    numeroPaiement: string;
    codeService: string;
}
  
export const useReservationStore = defineStore('reservation', {
    state: (): ReservationState => ({
      trajet: null,
      date: null,
      membres: 1,
      nom: '',
      prenoms: '',
      email: '',
      numero: '',
      bagages: 0,
      typePaiement: '',
      numeroPaiement: '',
      codeService: ''
    }),
    actions: {
      setTrajet(trajet: Trajet) {
        this.trajet = trajet;
      },
      setDate(date: string) {
        this.date = date;
      },
      setMembres(membres: number) {
        this.membres = membres;
      },
      setPersonalDetails(nom: string, prenoms: string, email: string, numero: string, bagages: number) {
        this.nom = nom;
        this.prenoms = prenoms;
        this.email = email;
        this.numero = numero;
        this.bagages = bagages;
      },
      setPaymentDetails(typePaiement: string, numeroPaiement: string, codeService: string) {
        this.typePaiement = typePaiement;
        this.numeroPaiement = numeroPaiement;
        this.codeService = codeService;
      },
      resetReservation() {
        this.$reset(); // Réinitialise l'état du store
      }
    },
    persist: {
      key: 'reservationData',
      storage: sessionStorage,
    },
});
  