<template>
    <div class="w-full fixed h-[70px] bg-gray-800 md:hidden top-0 flex justify-center items-center z-50 shadow-4xl">
        <div @click="goToReservationPage()" class="absolute cursor-pointer top-[50%] translate-y-[-50%] left-[30px] text-white text-sm font-bold">
            <i class="fa-solid fa-chevron-left"></i>
        </div>
        <h1 class="text-sm font-bold text-white">Réservation</h1>
    </div>
    <section v-if="stepOne" class="py-[20px] w-full mt-[70px] lg:h-screen md:h-[130vh] relative flex flex-col md:mt-0 justify-center items-center">
        <div class="container px-[20px] max-w-[1024px] flex flex-col justify-center items-center w-full relative">
            <div class="lg:h-[500px] h-auto w-full relative flex flex-col lg:flex-row justify-center items-center bg-gray-100/10 md:rounded-[50px] rounded-[25px] shadow-10xl">
                <div class="lg:w-[65%] w-full h-full overflow-hidden md:rounded-[50px] rounded-[25px] relative flex justify-center items-center">
                    <div class="w-full absolute z-10 overflow-hidden flex justify-center items-center h-full">
                        <img v-if="trajet.destination == 'Parakou'" src="../assets/images/parakou.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="trajet.destination == 'Natitingou'" src="../assets/images/nati.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="trajet.destination == 'Porto Novo'" src="../assets/images/porto-novo.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="trajet.destination == 'Bantè'" src="../assets/images/bante.jpeg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="trajet.destination == 'Grand Popo'" src="../assets/images/grand-popo.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="trajet.destination == 'Ouidah'" src="../assets/images/ouidah.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="trajet.destination == 'Calavi' || trajet.destination == 'Abomey Calavi'" src="../assets/images/calavi.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="trajet.destination == 'Bohicon'" src="../assets/images/bohicon.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else src="../assets/images/indisponible.jpg" alt="" class="w-full h-full absolute object-left-top object-cover">
                        </div>
                    <div class="bg-gray-800/60 md:p-[50px] p-[25px] flex flex-col w-full h-full z-20 relative">
                        <div class="w-full relative flex justify-between items-center text-white text-sm font-bold">
                            <div class="logo flex text-sm font-bold">
                                <h1>Bénin Ease Travel</h1>
                            </div>
                            <div class="logo flex text-xl font-bold">
                                <i class="fa-solid fa-bus-simple text-xl"></i>
                            </div>
                        </div>
                        <div class="w-full flex flex-col justify-start items-start mt-[40px] relative">
                            <h1 class="md:text-4xl text-2xl font-bold text-white flex flex-col">
                                <span>Un nouveau</span><span>Départ pour {{ trajet.destination }}</span>
                            </h1>
                            <button class="px-[10px] py-[5px] rounded-[10px] mt-[20px] text-gray-200 bg-gray-800 font-bold text-sm text-center">En savoir plus</button>
                        </div>
                        <div class="w-full mt-[40px] relative hidden lg:flex flex-col">
                            <h1 class="text-white text-sm font-bold">Offres spéciales</h1>
                            <div class="flex justify-between items-center mt-[10px] w-full relative">
                                <div v-for="(trajet, index) in trajets" :key="index" class="w-[calc(50%-10px)] h-[150px] rounded-[20px] bg-white p-[10px] shadow-4xl flex relative">
                                    <div class="w-[40%] h-full rounded-[10px] flex relative justify-center items-center overflow-hidden">
                                        <img v-if="trajet.destination == 'Parakou'" src="../assets/images/parakou.jpg" alt="" class="w-full h-full absolute object-cover">
                                        <img v-else-if="trajet.destination == 'Natitingou'" src="../assets/images/nati.jpg" alt="" class="w-full h-full absolute object-cover">
                                        <img v-else-if="trajet.destination == 'Porto Novo'" src="../assets/images/porto-novo.jpg" alt="" class="w-full h-full absolute object-cover">
                                        <img v-else-if="trajet.destination == 'Bantè'" src="../assets/images/bante.jpeg" alt="" class="w-full h-full absolute object-cover">
                                        <img v-else-if="trajet.destination == 'Grand Popo'" src="../assets/images/grand-popo.jpg" alt="" class="w-full h-full absolute object-cover">
                                        <img v-else-if="trajet.destination == 'Ouidah'" src="../assets/images/ouidah.jpg" alt="" class="w-full h-full absolute object-cover">
                                        <img v-else-if="trajet.destination == 'Calavi' || trajet.destination == 'Abomey Calavi'" src="../assets/images/calavi.jpg" alt="" class="w-full h-full absolute object-cover">
                                        <img v-else-if="trajet.destination == 'Bohicon'" src="../assets/images/bohicon.jpg" alt="" class="w-full h-full absolute object-cover">
                                        <img v-else src="../assets/images/indisponible.jpg" alt="" class="w-full h-full absolute object-left-top object-cover">
                                    </div>
                                    <div class="w-[60%] flex flex-col px-[10px]">
                                        <h1 class="text-sm font-bold text-gray-800">{{ trajet.destination }}</h1>
                                        <h2 class="text-xs font-semibold text-gray-400">Depuis {{ trajet.depart }}</h2>
                                        <div class="w-full mt-[5px]">
                                            <h1 class="text-xl font-bold text-gray-800"> {{ trajet.prix_reduit }} <span class="text-sm text-gray-500">FCFA</span></h1>
                                            <p class="text-sm my-[5px] text-gray-500 font-bold">
                                                {{ trajet.heureDepart }}
                                            </p>
                                            <router-link :to="'/confirmation/reservation/'+ trajet._id" class="text-gray-200 mt-[10px] font-bold bg-red-600 w-full rounded-[5px] px-[10px] py-[5px] text-sm">Réserver</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lg:w-[35%] w-full md:p-[50px] p-[25px] h-full flex flex-col justify-end items-center">
                    <h1 class="text-2xl font-bold text-gray-800 text-center capitalize">Réserver un trajet</h1>
                    <div class="flex justify-between items-center my-[20px] w-full relative">
                        <div class="w-full relative flex justify-between items-center">
                            <div class="w-[48%] relative my-[10px]">
                                <input type="date" v-model="selectedDate" @change="formatSelectedDate" class="w-full required p-[10px] rounded-[5px] border border-gray-300 font-semibold text-sm text-gray-800 outline-none bg-transparent"
                                placeholder="Date" />
                                <span v-if="obligeDate" class="text-sm text-red-600 font-semibold"><i class="fa-solid fa-circle-info"></i> Obligatoire</span>
                            </div>
                            <div class="w-[48%] relative my-[10px]">
                                <input type="text" v-model="membres" name="membres" readonly id="membres" value="1" required class="w-full required p-[10px] placeholder:text-gray-800 rounded-[5px] border border-gray-300 font-semibold text-sm text-gray-800 outline-none bg-transparent" placeholder="Membres">
                                <span v-if="obligeDate" class="text-sm text-red-600 font-semibold">-</span>
                            </div>
                        </div>
                    </div>
                    <div class="w-full relative flex justify-center items-center">
                        <div class="w-[calc(50%-35px)] relative">
                            <div class="w-full flex flex-col">
                                <p class="text-[10px] font-bold text-gray-400"> De </p>
                                <h1 class="text-2xl font-bold text-gray-600">{{ getFirstThreeLetters(trajet.depart) }}</h1>
                                <p class="text-xs font-bold text-gray-400"> {{ trajet.depart }} </p>
                            </div>
                            <div class="my-[10px] w-full h-[1px] degrade"></div>
                            <div class="w-full flex flex-col">
                                <p class="text-[10px] font-bold text-gray-400"> De </p>
                                <h1 class="text-sm font-bold text-gray-600">{{formattedSelectedDate}}, {{trajet.heureDepart}}</h1>
                            </div>
                            <div class="my-[10px] w-full h-[1px] degrade"></div>
                            <div class="w-full flex flex-col">
                                <p class="text-[10px] font-bold text-gray-400"> Confort </p>
                                <h1 class="text-sm font-bold text-gray-600">Basic</h1>
                            </div>
                        </div>
                        <div class="w-[70px] h-[160px] relative flex justify-center flex-col items-center">
                            <div class="w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-[50%] flex justify-center items-center bg-gray-400 text-white">
                                <i class="fa-solid fa-bus-simple text-sm"></i>
                            </div>
                            <div class="h-full w-[2px] degrade1"></div>
                        </div>
                        <div class="w-[calc(50%-35px)] relative">
                            <div class="w-full flex flex-col">
                                <p class="text-[10px] font-bold text-gray-400 uppercase"> à </p>
                                <h1 class="text-2xl font-bold text-gray-600">{{ getFirstThreeLetters(trajet.destination) }}</h1>
                                <p class="text-xs font-bold text-gray-400"> {{ trajet.destination }} </p>
                            </div>
                            <div class="my-[10px] w-full h-[1px] degrade"></div>
                            <div class="w-full flex flex-col">
                                <p class="text-[10px] font-bold text-gray-400 uppercase"> à </p>
                                <h1 class="text-sm font-bold text-gray-600">{{ getHeureDestination(trajet.heureDepart, trajet.duree) }}</h1>
                            </div>
                            <div class="my-[10px] w-full h-[1px] degrade"></div>
                            <div class="w-full flex flex-col">
                                <p class="text-[10px] font-bold text-gray-400"> Membres </p>
                                <h1 class="text-sm font-bold text-gray-600">{{ membres }}</h1>
                            </div>
                        </div>
                    </div>
                    <button @click="OpenStepTwo()" class="w-full text-center rounded-[5px] bg-gray-800 font-bold text-sm text-white mt-[20px] p-[10px]">
                        Poursuivre la réservation
                    </button>
                </div>
            </div>
        </div>
    </section>
    <section v-if="!stepOne" class="py-[20px] flex w-full relative flex-col mt-[55px] md:mt-0 justify-center items-center">
        <div class="container px-[20px] max-w-[1024px] flex flex-col justify-center items-center w-full relative">
            <div class="w-[90%] flex mt-[25px] relative justify-between items-center max-w-[800px]">
                <div class="flex justify-start items-center">
                    <button class="p-[10px] md:rounded-[10px] rounded-[5px] w-[30px] h-[30px] md:w-[45px] md:h-[45px] text-white font-bold text-sm bg-green-600 flex justify-center items-center"><span><i class="fa-solid fa-check"></i></span></button>
                    <p class="text-center text-xs font-semibold ml-[10px] hidden md:flex text-green-600">Réservation</p>
                </div>
                <div class="border-b-2 border-green-600 md:w-[90px] w-[45px] relative top-[2.5px]">

                </div>
                <div class="flex justify-start items-center" >
                    <button class="p-[10px] md:rounded-[10px] rounded-[5px] w-[30px] h-[30px] md:w-[45px] md:h-[45px] font-bold text-sm flex justify-center items-center" :class="{'bg-green-600 text-white': stepThree || stepFour, 'bg-gray-300 text-gray-500': stepTwo || stepOne}"><span :class="{'hidden': stepThree || stepFour}">2</span><span :class="{'hidden': stepTwo || stepOne }"><i class="fa-solid fa-check"></i></span></button>
                    <p class="text-center text-xs font-semibold ml-[10px] hidden md:flex" :class="{'text-green-600': stepThree || stepFour, 'text-gray-500': stepTwo || stepOne}">Informations</p>
                </div>
                <div class="border-b-2 md:w-[90px] w-[45px] relative top-[2.5px]" :class="{'border-green-600': stepThree || stepFour, 'border-gray-300': stepTwo || stepOne}">

                </div>
                <div class="flex justify-start items-center">
                    <button class="p-[10px] md:rounded-[10px] rounded-[5px] w-[30px] h-[30px] md:w-[45px] md:h-[45px] font-bold text-sm flex justify-center items-center" :class="{'bg-green-600 text-white': stepFour, 'bg-gray-300 text-gray-500': stepTwo || stepOne || stepThree}"><span :class="{'hidden': stepFour}">3</span><span :class="{'hidden': stepTwo || stepOne || stepThree }"><i class="fa-solid fa-check"></i></span></button>
                    <p class="text-center text-xs font-semibold ml-[10px] hidden md:flex" :class="{'text-green-600': stepFour, 'text-gray-500': stepTwo || stepOne || stepThree}">Paiement</p>
                </div>
                <div class="border-b-2 md:w-[90px] w-[45px] relative top-[2.5px]"  :class="{'border-green-600': stepFour, 'border-gray-300': stepTwo || stepOne || stepThree}">

                </div>
                <div class="flex justify-start items-center">
                    <button class="p-[10px] md:rounded-[10px] rounded-[5px] w-[30px] h-[30px] md:w-[45px] md:h-[45px] text-gray-500 font-bold text-sm bg-gray-300 flex justify-center items-center">4</button>
                    <p class="text-center text-xs font-semibold text-gray-500 ml-[10px] hidden md:flex">Détails</p>
                </div>
            </div>
        </div>
        <div id="parent" class="container px-[20px] flex flex-col h-[calc(100vh-250px)] md:h-auto mt-[10px] overflow-y-auto justify-start items-center w-full relative">
            <div class="w-full relative flex justify-center md:items-start items-center md:flex-row flex-col">
                <div class="md:w-[70%] w-full relative m-[10px] bg-white shadow-4xl rounded-[20px] p-[20px]">
                    <form v-if="stepTwo" action="" class="w-full relative flex flex-col">
                        <h1 class="text-gray-700 font-bold mb-[20px]">Veillez renseigner des Informations correctes</h1>
                        <div class="w-full relative flex justify-between items-center md:flex-row flex-col">
                            <div class="md:w-[48%] w-full relative my-[10px]">
                                <input type="text" v-model="nom" id="nom" :class="{'not-empty border-green-600': nom.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                                <label for="nom" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Nom <span class="text-red-600">*</span></label>
                                <span id="nom-error" class="text-red-600 text-sm"></span>
                            </div>
                            <div class="md:w-[48%] w-full relative my-[10px]">
                                <input type="text" v-model="prenoms" id="prenoms" :class="{'not-empty border-green-600': prenoms.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                                <label for="prenoms" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Prénoms <span class="text-red-600">*</span></label>
                                <span id="prenoms-error" class="text-red-600 text-sm"></span>
                            </div>
                        </div>
                        <div class="w-full relative my-[10px]">
                            <input type="text" v-model="email" id="email" :class="{'not-empty border-green-600': email.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                            <label for="email" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Email</label>
                            <span id="email-error" class="text-red-600 text-sm"></span>
                        </div>
                        <div class="w-full relative my-[10px]">
                            <input type="text" v-model="phone" id="phone" :class="{'not-empty border-green-600 pl-[10px]': phone.trim() !== ''}" placeholder="Numéro *" class="w-full p-[10px] placeholder:text-gray-800 placeholder:font-bold placeholder:text-sm focus:pl-[10px] pl-[50px] valid:pl-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                            
                            <span id="phone-error" class="text-red-600 text-sm"></span>
                        </div>
                        <div class="w-full relative my-[10px]">
                            <input type="text" v-model="bagage" id="bagage" :class="{'not-empty border-green-600': bagage.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                            <label for="bagage" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Nombre de bagage <span class="text-red-600">*</span></label>
                            <span id="bagage-error" class="text-red-600 text-sm"></span>
                        </div>
                    </form>
                    <form v-if="stepThree" action="" class="w-full relative flex flex-col">
                        <h1 class="text-gray-700 font-bold mb-[20px]">Passez au Paiement</h1>
                        <div class="">
                            <div class="w-full flex justify-between items-center flex-wrap relative">
                                <div class="flex items-center my-[10px]">
                                    <input type="radio" name="paiement" value="espece" id="espèce" v-model="selectedPaymentMethod">
                                    <label for="espèce" class="mx-[5px] text-sm font-semibold text-gray-700">En espèce</label>
                                </div>
                                <div class="flex items-center my-[10px]">
                                    <input type="radio" name="paiement" value="mobile" id="mobile" v-model="selectedPaymentMethod">
                                    <label for="mobile" class="mx-[5px] text-sm font-semibold text-gray-700">Paiement mobile</label>
                                </div>
                                <div class="flex items-center my-[10px]">
                                    <input type="radio" name="paiement" unselectable="yes" value="carte" id="carte" v-model="selectedPaymentMethod">
                                    <label for="carte" class="mx-[5px] text-sm font-semibold text-gray-700">Paiement par carte</label>
                                </div>
                            </div>
                            <div v-if="selectedPaymentMethod === 'espece'" class="w-full relative flex items-center">
                                <div class="my-[10px] w-full relative">
                                    <input type="text" v-model="espece" id="espece" :class="{'not-empty border-green-600': espece.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                                    <label for="espece" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Entrez le code de service</label>
                                </div>
                                <div class="md:ml-[20px] ml-[10px] my-[10px] relative">
                                    <button class="bg-gray-800 text-sm font-bold text-white border border-gray-800 px-[20px] py-[10px] md:w-auto w-full">Vérifier</button>
                                </div>
                            </div>
                            <div v-if="selectedPaymentMethod === 'mobile'" class="my-[10px] w-full relative">
                                <input type="text" v-model="mobile" id="mobile" :class="{'not-empty border-green-600': mobile.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                                <label for="mobile" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Entrez le numéro Momo</label>
                            </div>
                        </div>
                        
                    </form>
                    <div v-if="stepFour" class="w-full relative flex flex-col step-four-content">
                        <div class="p-[10px] w-full rounded-t-[10px] bg-green-600/20 flex items-start">
                            <div class="w-[50px] h-[50px] min-w-[50px] min-h-[50px] rounded-[5px] bg-white flex justify-center items-center text-green-600">
                                <i class="fa-solid fa-check"></i>
                            </div>
                            <div class="md:ml-[20px] ml-[10px] w-full relative flex flex-col">
                                <h1 class="text-xs font-bold text-gray-800">Votre réservation est confirmée</h1>
                                <p class="text-[10px] mt-[5px]">
                                    Il est important et impératif que vous fassiez la capture d'écran du code QR si dessous 
                                    <span class="hidden md:flex">
                                        ou que vous téléchargiez le reçu contenant le code QR sans quoi il vous serait probablement refusé l'accès au bus
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div class="w-full p-[10px] relative">
                            <h1 class="text-sm font-bold text-gray-800">Détails de la réservation</h1>
                            <div class="flex justify-between items-center mt-[25px] ">
                                <div class="flex items-center">
                                    <div class="w-[50px] h-[50px] rounded-[5px] shadow-4xl overflow-hidden flex justify-center items-center relative">
                                        <img v-if="trajetInfo.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                                        <img v-if="trajetInfo.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                                        <img v-if="trajetInfo.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                                        <img v-if="trajetInfo.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                                    </div>
                                    <h1 class="text-sm font-bold text-gray-800 mx-[5px]">{{ trajetInfo.nomCompagnie }}</h1>
                                </div>
                                <div class="flex items-end flex-col">
                                    <p class="text-gray-500 text-xs font-bold">Prix net</p>
                                    <h1 class="font-bold text-2xl flex flex-wrap text-green-600">{{ trajetInfo.prix_reduit }} FCFA</h1>
                                </div>
                            </div>
                            <div class="w-full relative flex justify-between my-[20px] flex-wrap">
                                <div class="my-[10px] md:w-[30%] w-[48%]">
                                    <p class="text-gray-500 text-xs font-bold">Nom</p>
                                    <h1 class="font-bold text-gray-800 flex flex-wrap">{{prenomsStore}} {{nomStore}}</h1>
                                </div>
                                <div class="my-[10px] md:w-[30%] w-[48%]">
                                    <p class="text-gray-500 text-xs font-bold">Numéro</p>
                                    <h1 class="font-bold text-gray-800 "><span class="text-sm text-gray-700">+229</span> {{numeroPaiement}}</h1>
                                </div>
                                <div class="my-[10px] md:w-[30%] w-[48%]">
                                    <p class="text-gray-500 text-xs font-bold">Nombre de personne</p>
                                    <h1 class="font-bold text-gray-800">{{membres}}</h1>
                                </div>
                                <div class="my-[10px] md:w-[30%] w-[48%]">
                                    <p class="text-gray-500 text-xs font-bold">Bagages</p>
                                    <h1 class="font-bold text-gray-800">{{ bagages }}</h1>
                                </div>
                                <div class="my-[10px] w-full">
                                    <p class="text-gray-500 text-xs font-bold">Email</p>
                                    <h1 class="font-bold text-gray-800 flex flex-wrap"> <span v-if="emailStore === undefined || emailStore === null">-</span> <span>{{ emailStore }}</span></h1>
                                </div>
                                <div class="my-[10px] w-full">
                                    <p class="text-gray-500 text-xs font-bold">ID de la réservation</p>
                                    <h1 class="font-bold text-gray-800 flex flex-wrap">#{{reservationId}}</h1>
                                </div>
                            </div>
                        </div>
                        <div class="w-full relative p-[10px] rounded-[5px] bg-gray-100 flex flex-col justify-center items-center">
                            <h1 class="text-center text-sm font-bold">Billet de réservation</h1>
                            <p class="text-xs font-semibold max-w-[550px] text-gray-500 my-[10px] px-[20px] text-center">
                                Veillez cliquer sur le bouton Téléchargé ci dessous pour télécharger votre billet d'accès, sans ce code QR il est possible que l'accès au bus vous soit refusé
                            </p>
                            <div class="w-full relative flex justify-center items-center">
                                <div v-if="qrCodePath" class="w-[110px] h-[110px] flex justify-center items-center relative">
                                    <img :src="localQrCodePath" alt="QR Code" class="w-full h-full absolute object-contain" @load="onImageLoad">
                                </div>
                                <div class="ml-[10px]">
                                    <div>
                                        <p class="text-gray-500 text-xs font-bold">ID</p>
                                        <h1 class="font-bold text-gray-800 flex flex-wrap">#{{ reservationId }}</h1>
                                    </div>
                                    <button @click="downloadQRCode()" class="px-[20px] py-[10px] mt-[10px] rounded-[5px] bg-gray-800 font-bold text-sm text-white">
                                        Télécharger
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md:w-[30%] w-full relative m-[10px] bg-white shadow-4xl rounded-[20px] p-[20px]">
                    <h1 class="text-sm font-bold text-gray-800">
                        Résumé de la réservation
                    </h1>
                    <div class="p-[10px] my-[5px] rounded-[10px] border border-gray-200 w-full relative">
                        <div class="w-full relative flex">
                            <div class="w-[48%] relative flex flex-col">
                                <span class="text-xs font-semibold text-gray-500">De</span>
                                <h1 class="text-xs font-bold text-gray-800">{{ date }}, {{ trajetInfo.heureDepart }}</h1>
                                <h2 class="text-[10px] font-bold text-gray-400">De {{ trajetInfo.depart }}</h2>
                            </div>
                            <div class="w-[48%] relative flex flex-col ml-[10px]">
                                <span class="text-xs font-semibold text-gray-500 uppercase">à</span>
                                <h1 class="text-xs font-bold text-gray-800">{{ getHeureDestination1(date, trajet.heureDepart, trajet.duree) }}</h1>
                                <h2 class="text-[10px] font-bold text-gray-400 capitalize">à {{ trajetInfo.destination }}</h2>
                            </div>
                        </div>
                        <div class="w-full flex relative">
                            <div class="flex justify-start items-center my-[10px] mr-[10px]">
                                <h1 class="text-sm font-bold text-gray-500 flex justify-center items-center">1 <span class="text-gray-500 ml-[5px]"><i class="fa-solid fa-user"></i></span></h1>
                            </div>
                            <div v-if="stepThree || stepFour" class="flex justify-start items-center my-[10px] mx-[10px]">
                                <h1 class="text-sm font-bold text-gray-500 flex justify-center items-center">{{ bagages }} <span class="text-gray-500 ml-[5px]"><i class="fa-solid fa-tags"></i></span></h1>
                            </div>
                            <div v-if="stepFour" class="flex justify-start items-center my-[10px] mx-[10px]">
                                <h1 class="text-sm font-bold flex justify-center items-center text-green-600 "><span class="mr-[5px]"><i class="fa-solid fa-circle-check"></i></span> Payé</h1>
                            </div>
                        </div>
                    </div>
                    <h1 class="text-sm font-bold text-gray-800">
                        Votre résumé de prix
                    </h1>
                    <div class="w-full relative flex flex-col mt-[15px]">
                        <p class="flex justify-between items-center my-[5px]">
                            <span class="text-xs font-bold text-gray-500">Réservation du trajet</span>
                            <span class="text-sm font-bold text-gray-800">{{ trajetInfo.prix_reduit }}.00<span class="text-[10px] font-bold text-gray-500">FCFA</span></span>
                        </p>
                        <p class="flex justify-between items-center my-[5px]">
                            <span class="text-xs font-bold text-gray-500">Autres </span>
                            <span class="text-sm font-bold text-gray-800">0.00<span class="text-[10px] font-bold text-gray-500">FCFA</span></span>
                        </p>
                        <p class="flex justify-between items-center my-[5px]">
                            <span class="text-xs font-bold text-gray-500">Taxe</span>
                            <span class="text-sm font-bold text-gray-800">{{'<02%'}} </span>
                        </p>
                        <p class="flex justify-between items-center my-[10px] text-green-700">
                            <span class="text-sm font-bold">Montant total</span>
                            <span class="text-sm font-bold"> {{prix2(trajetInfo.prix_reduit)}}.00<span class="text-[10px] font-bold">FCFA</span></span>
                        </p>
                    </div>
                    <div class="md:flex hidden w-full relative mt-[20px] justify-between items-center">
                        <button v-if="stepTwo" @click="OpenStepOne()" class="p-[10px] text-sm font-bold bg-white text-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Précedent</button>
                        <button v-if="stepTwo" @click="OpenStepThree()" class="p-[10px] text-sm font-bold text-white bg-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Suivant</button>
                        <button v-if="stepThree" @click="OpenStepTwoA()" class="p-[10px] text-sm font-bold bg-white text-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Précedent</button>
                        <button v-if="stepThree && selectedPaymentMethod === 'espece'" class="p-[10px] text-sm font-bold text-white bg-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Confirmer</button>
                        <button v-if="stepThree && selectedPaymentMethod !== 'espece'" @click="payerReservation()" class="p-[10px] text-sm font-bold text-white bg-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Payer</button>
                        <button v-if="stepFour" @click="generatePDF" class="p-[10px] text-sm font-bold text-white bg-green-700 border border-green-700 rounded-[5px] w-full text-center">Télécharger la facture</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed md:hidden bottom-0 left-0 w-full p-[20px] shadow-4xl flex justify-between items-center">
            <button v-if="stepTwo" @click="OpenStepOne()" class="p-[10px] text-sm font-bold bg-white text-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Précedent</button>
            <button v-if="stepTwo" @click="OpenStepThree()" class="p-[10px] text-sm font-bold text-white bg-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Suivant</button>
            <button v-if="stepThree" @click="OpenStepTwoA()" class="p-[10px] text-sm font-bold bg-white text-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Précedent</button>
                        <button v-if="stepThree && selectedPaymentMethod === 'espece'" class="p-[10px] text-sm font-bold text-white bg-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Confirmer</button>
                        <button v-if="stepThree && selectedPaymentMethod !== 'espece'" @click="payerReservation()" class="p-[10px] text-sm font-bold text-white bg-gray-800 border border-gray-800 rounded-[5px] w-[45%] text-center">Payer</button>
            <button @click="generatePDF" v-if="stepFour" class="p-[10px] text-sm font-bold text-white bg-green-700 border border-green-700 rounded-[5px] w-full text-center">Télécharger la facture</button>
        </div>

        <div v-if="isRefused || isLoadingD" @click="isRefused = false, isLoadingD = false" class="w-full h-full bg-gray-700 p-[20px] duration-300 ease-in-out shadow-4xl fixed top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] flex flex-col justify-start items-center ">

        </div>
        <div class="w-[250px] h-[225px] bg-white p-[20px] duration-300 shadow-4xl fixed top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] flex flex-col justify-start items-center scale-0 " :class="{'scale-100': isRefused == true || isLoadingD == true}">
            <i v-if="isRefused" class="fa-solid fa-triangle-exclamation text-4xl text-red-500"></i>
            <i v-if="isLoadingD" class="fa-spin fa-solid fa-spinner spin text-4xl text-red-500"></i>
            <h1 v-if="isRefused" class="text-center text-sm font-bold mt-[15px] text-gray-500">Oh erreur !</h1>
            <h1 v-if="isRefused" class="text-center text-sm font-bold mt-[15px] text-gray-500">Votre transaction a échoué ! Veillez réessayer.</h1>
            <h1 v-if="isLoadingD" class="text-center text-sm font-bold mt-[15px] text-gray-500">En attente !</h1>
            <h1 v-if="isLoadingD" class="text-center text-sm font-bold mt-[15px] text-gray-500">Votre transaction n'a pas encore été effectuée</h1>
            <button @click="isRefused = false, isLoadingD = false " class="absolute bottom-0 left-0 right-0 text-sm font-bold w-full text-white bg-red-500 p-[10px] text-center">
                <span v-if="isRefused">
                    Réessayer
                </span>
                <span v-if="isLoadingD">...</span>
            </button>
        </div>
    </section>
</template>

<script setup>
import HeaderComponent from "../components/HeaderComponent.vue"
import { ref, onMounted, computed, watch } from "vue"
import { useRouter } from 'vue-router'
import axios from 'axios'
import { useReservationStore } from '../store/reservation';
import html2pdf from 'html2pdf.js';
import { useRoute } from 'vue-router'
const qrCodePath = ref('');
const isImageLoaded = ref(false);

const localQrCodePath = ref('');
const onImageLoad = () => {
  isImageLoaded.value = true;
};

const downloadQrCodeImage = async () => {
  try {
    const response = await axios.get(qrCodePath.value, { responseType: 'blob' });
    const blob = response.data;
    const localPath = URL.createObjectURL(blob);
    localQrCodePath.value = localPath;
  } catch (error) {
    ErrorMessage.value = 'Error downloading QR code image';
  }
};

const generatePDF = async () => {
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  try {
    await loadImage(localQrCodePath.value);
    const element = document.querySelector('.step-four-content'); // Sélectionner l'élément contenant les informations de réservation
    // Options de génération du PDF
    const options = {
      margin: 0.5,
      filename: 'reservation.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    // Générer et télécharger le PDF
    await html2pdf().set(options).from(element).save();
  } catch (error) {
    alert("Erreur lors du chargement de l'image du QR code. Veuillez réessayer.");
  }
};

const downloadQRCode = () => {
  const link = document.createElement('a');
  link.href = localQrCodePath.value;
  link.download = 'qr_code.png';
  link.click();
};

const trajets = ref([])
const fetchTrajets = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(`/api/trajets`);
    const now = new Date();

    // Filtrer les trajets à venir et ceux déjà passés
    const upcomingTrajets = [];
    const pastTrajets = [];

    response.data.forEach(trajet => {
      const [hours, minutes] = trajet.heureDepart.split(':').map(Number);
      const departureTime = new Date();
      departureTime.setHours(hours, minutes, 0, 0);

      if (departureTime > now) {
        upcomingTrajets.push(trajet);
      } else {
        pastTrajets.push(trajet);
      }
    });

    // Trier les trajets par heure de départ
    upcomingTrajets.sort((a, b) => {
      const [hoursA, minutesA] = a.heureDepart.split(':').map(Number);
      const [hoursB, minutesB] = b.heureDepart.split(':').map(Number);
      const timeA = new Date();
      const timeB = new Date();
      timeA.setHours(hoursA, minutesA, 0, 0);
      timeB.setHours(hoursB, minutesB, 0, 0);
      return timeA - timeB;
    });

    pastTrajets.sort((a, b) => {
      const [hoursA, minutesA] = a.heureDepart.split(':').map(Number);
      const [hoursB, minutesB] = b.heureDepart.split(':').map(Number);
      const timeA = new Date();
      const timeB = new Date();
      timeA.setHours(hoursA, minutesA, 0, 0);
      timeB.setHours(hoursB, minutesB, 0, 0);
      return timeA - timeB;
    });

    // Combiner les trajets pour atteindre 4 trajets
    const combinedTrajets = [...upcomingTrajets, ...pastTrajets].slice(0, 2);
    trajets.value = combinedTrajets;
  } catch (err) {
    error.value = true;
  } finally {
    isLoading.value = false;
  }
};

const prixDeux = ref(0)
function prix2(prix) {
    prixDeux.value = prix + (prix * 0.02)
    return prixDeux
}

const reservationStore = useReservationStore();

const isLoadingD = ref(false)
const trajetInfo = computed(() => reservationStore.trajet);
const date = computed(() => reservationStore.date);
const bagages = computed(() => reservationStore.bagages);
const nomStore = computed(() => reservationStore.nom);
const prenomsStore = computed(() => reservationStore.prenoms);
const emailStore = computed(() => reservationStore.email);
const numero = computed(() => reservationStore.numero);
const moyenPaiement = computed(() => reservationStore.typePaiement);
const numeroPaiement = computed(() => reservationStore.numeroPaiement);
const codeservice = computed(() => reservationStore.codeService);
const emailNull = ref("");

if (emailStore.value === undefined || emailStore.value === null) {
    emailNull.value = "agossamickel01@gmail.com";
} else {
    emailNull.value = emailStore.value;
}
const router = useRouter()
function goToReservationPage() {
    router.push('/reservation')
}

const updateTrajet = () => {
  reservationStore.setTrajet({
    depart: trajet.value.depart,
    destination: trajet.value.destination,
    heureDepart: trajet.value.heureDepart,
    duree: Number(trajet.value.duree), // Assurez-vous que la durée est un nombre
    prix: trajet.value.prix,
    prix_reduit: trajet.value.prix_reduit,
    places: trajet.value.places,
    idCompagnie: trajet.value.idCompagnie,
    nomCompagnie: trajet.value.nomCompagnie,
    idTrajet: trajet.value.idTrajet,
  });
};

const membres = ref(1)
const trajet = ref({})
const error = ref(false)
const isLoading = ref(false)
const trajetId = router.currentRoute.value.params.id;
const obligeDate = ref(false)
const prenoms = ref('')
const nom = ref('')
const email = ref('')
const phone = ref('')
const bagage = ref('')
const selectedPaymentMethod = ref('mobile');
const espece = ref('')
const mobile = ref('')
const codeService = ref('')
const pending = ref('')
const isRefused = ref(false)
const ErrorMessage = ref('')
const SuccessMessage = ref('')
const reservationId = ref('')

const fetchTrajetById = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(`/api/trajets/${trajetId}`);
    trajet.value = response.data;
    updateTrajet()
  } catch (err) {
    error.value = true;
  } finally {
    isLoading.value = false;
  }
};

const getFirstThreeLetters = (str) => {
  if (!str) return '';
  return str.substring(0, 3).toUpperCase();
};


const updateDateAndMembres = () => {
  reservationStore.setDate(selectedDate.value);
  reservationStore.setMembres(membres.value);
};

const updatePersonalDetails = () => {
  reservationStore.setPersonalDetails(nom.value, prenoms.value, email.value, phone.value, bagage.value);
};

const updatePaymentDetails = () => {
  reservationStore.setPaymentDetails(selectedPaymentMethod.value, mobile.value, codeService.value);
};

onMounted(() => {
    fetchTrajetById()
    fetchTrajets()
})


const payerReservation = async () => {
    updatePaymentDetails ()
    try {
        const ReservationData = {
            idTrajet: trajetId, 
            nom: nomStore.value, 
            prenoms: prenomsStore.value, 
            email: emailNull, 
            numero: mobile.value,
            prix_reduit: trajetInfo.value.prix_reduit,
            description: `Paiement de ${trajetInfo.value.prix_reduit}F pour la réservation du trajet ${trajetInfo.value.depart} ${trajetInfo.value.destination} avec la compagnie ${trajetInfo.value.nomCompagnie}`
        };
        const response = await axios.post('/paiement', ReservationData);

        window.location.href = response.data.redirectUrl;
    } catch (error) {
        ErrorMessage.value ='Erreur lors de la création de la réservation';
    }
};

const submitReservation = async () => {
  const reservation = ref({
    nom: nomStore.value,
    prenoms: prenomsStore.value,
    email: emailNull.value,
    numero: Number(numero.value),
    nombreBagage: Number(bagages.value),
    moyenPaiement: moyenPaiement.value,
    numeroPaiement: Number(numeroPaiement.value),
    codeService: codeservice,
    idCompagnie: trajetInfo.value.idCompagnie,
    date: date.value,
    depart: trajetInfo.value.depart,
    destination: trajetInfo.value.destination,
    confort: 'Basic',
    nombrePersonne: Number(membres.value),
  });

  try {
    const response = await axios.post('/api/reservations', reservation.value);

    qrCodePath.value = response.data.qrCodePath;
    reservationId.value = response.data.idReservation;
    await downloadQrCodeImage();

    stepOne.value = false;
    stepTwo.value = false;
    stepThree.value = false;
    stepFour.value = true;
    router.push({ path: `/confirmation/reservation/${trajetId}/`, query: { page: 'four', status: 'pending', close: 'true' } });
  } catch (error) {
    ErrorMessage.value = "Erreur lors de l'ajout de la réservation";
    stepOne.value = false;
    stepTwo.value = false;
    stepThree.value = true;
    stepFour.value = false;
  }
};


const getHeureDestination1 = (date, heureDepart, duree) => {
  if (!date || !heureDepart || duree === undefined) return ''; // Vérifiez si les données sont disponibles

  // Extraire les éléments de la date
  const [year, month, day] = date.split('-').map(Number);
  const [hours, minutes] = heureDepart.split(':').map(Number);

  // Créer un objet Date à partir des informations
  const departDate = new Date(year, month - 1, day, hours, minutes, 0, 0); // Mois commence à 0

  // Convertir la durée en minutes
  const dureeMinutes = duree * 60;

  // Ajouter la durée en minutes
  departDate.setMinutes(departDate.getMinutes() + dureeMinutes);

  // Extraire les composants de la date de destination
  const destinationDay = departDate.getDate();
  const destinationMonth = departDate.getMonth();
  const destinationHours = String(departDate.getHours()).padStart(2, '0');
  const destinationMinutes = String(departDate.getMinutes()).padStart(2, '0');

  // Noms des mois
  const monthNames = ["jan", "fév", "mar", "avr", "mai", "jun", "jui", "aoû", "sep", "oct", "nov", "déc"];
  const formattedMonth = monthNames[destinationMonth];

  return `${destinationDay} ${formattedMonth}, ${destinationHours}:${destinationMinutes}`;
};


const getHeureDestination = (heureDepart, duree) => {
  if (!heureDepart || duree === undefined || !selectedDate.value) return ''; // Vérifiez si les données sont disponibles

  const [hours, minutes] = heureDepart.split(':').map(Number);
  const departDate = new Date(selectedDate.value); // Utilisez la date sélectionnée
  departDate.setHours(hours, minutes, 0, 0);

  // Convertir la durée en minutes
  const dureeMinutes = duree * 60;

  // Ajouter la durée en minutes
  departDate.setMinutes(departDate.getMinutes() + dureeMinutes);

  const destinationDay = departDate.getDate();
  const destinationMonth = departDate.getMonth();
  const destinationHours = String(departDate.getHours()).padStart(2, '0');
  const destinationMinutes = String(departDate.getMinutes()).padStart(2, '0');

  const monthNames = ["jan", "fév", "mar", "avr", "mai", "jun", "jui", "aoû", "sep", "oct", "nov", "déc"];
  const formattedMonth = monthNames[destinationMonth];

  return `${destinationDay} ${formattedMonth}, ${destinationHours}:${destinationMinutes}`;
};


const formatDate = (date) => {
  if (!date) return '';
  const day = date.getDate();
  const monthNames = ["jan", "fév", "mar", "avr", "mai", "jun", "jui", "aoû", "sep", "oct", "nov", "déc"];
  const month = monthNames[date.getMonth()];
  return `${day} ${month}`;
};

const formattedSelectedDate = ref('');

const stepOne = ref(true)
const stepTwo = ref(false)
const stepThree = ref(false)
const stepFour = ref(false)

const selectedDate = ref(null);

const formatSelectedDate = () => {
  if (!selectedDate.value) return '';
  const date = new Date(selectedDate.value);
  formattedSelectedDate.value = formatDate(date);
};


function OpenStepOne() {
    stepOne.value = true,
    stepTwo.value = false,
    stepThree.value = false,
    stepFour.value = false
}
function OpenStepTwoA() {
    stepOne.value = false,
    stepTwo.value = true,
    stepThree.value = false,
    stepFour.value = false
}

function OpenStepTwo() {
    // Vérifiez si la date est remplie et non vide
    if (selectedDate.value && selectedDate.value !== 'Invalid Date') {
        stepOne.value = false;
        stepTwo.value = true;
        stepThree.value = false;
        stepFour.value = false;
        updateDateAndMembres();
    } else {
        // Optionnel : Affichez un message d'erreur ou effectuez une autre action si la date n'est pas remplie
        obligeDate.value = true 
    }
}


function OpenStepThree() {
    // Initialisation des messages d'erreur
    let hasError = false;

    // Vérification des champs
    if (nom.value.trim() === '') {
        hasError = true;
        document.getElementById('nom-error').textContent = 'Le nom est requis.';
    } else {
        document.getElementById('nom-error').textContent = '';
    }

    if (prenoms.value.trim() === '') {
        hasError = true;
        document.getElementById('prenoms-error').textContent = 'Les prénoms sont requis.';
    } else {
        document.getElementById('prenoms-error').textContent = '';
    }

    /* if (email.value.trim() === '') {
        hasError = true;
        document.getElementById('email-error').textContent = 'L\'email est requis.';
    } else {
        document.getElementById('email-error').textContent = '';
    } */

    if (phone.value.trim() === '') {
        hasError = true;
        document.getElementById('phone-error').textContent = 'Le numéro est requis.';
    } else {
        document.getElementById('phone-error').textContent = '';
    }

    if (bagage.value.trim() === '') {
        hasError = true;
        document.getElementById('bagage-error').textContent = 'Le nombre de bagages est requis.';
    } else {
        document.getElementById('bagage-error').textContent = '';
    }

    // Exécuter la fonction uniquement si aucune erreur n'a été trouvée
    if (!hasError) {
        stepOne.value = false;
        stepTwo.value = false;
        stepThree.value = true;
        stepFour.value = false;
        updatePersonalDetails();
    }
}



const urlParams = new URLSearchParams(window.location.search);
const id = urlParams.get('id');
const verification = ref({})
const verificationId = async () => {
  try {
    const response = await axios.get(`/verification/${id}`);
    verification.value = response.data
    if (verification.value.message === "Transaction approuvée"){
        submitReservation()
    } else if (verification.value.message === "Transaction en attente") {
        isLoadingD.value = true
        stepOne.value = false;
        stepTwo.value = false;
        stepThree.value = true;
        stepFour.value = false;
    } else {
        isRefused.value = true
        stepOne.value = false;
        stepTwo.value = false;
        stepThree.value = true;
        stepFour.value = false;
    }
  } catch (err) {
    error.value = true;
  }
};
const route = useRoute();
const { query } = route;
const { page, status } = query;
function traiterPaiement(page, status) {
  if (page == 'four' && status === 'pending') {
    isLoadingD.value = true
    stepOne.value = false;
    stepTwo.value = false;
    stepThree.value = true;
    stepFour.value = false;
  } else if (status === 'approved' && page == 'four') {
    verificationId()
  } else {
    isRefused.value = true
    stepOne.value = false;
    stepTwo.value = false;
    stepThree.value = true;
    stepFour.value = false;
  }
}

const checkUrlAndExecute = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('page');
  const status = urlParams.get('status');

  if (page && status) {
    traiterPaiement(page, status);
  }
};

onMounted(() =>{
    checkUrlAndExecute()
});

</script>

<style>
.degrade {
    background: linear-gradient(to right, gray, rgba(128, 128, 128, 0.532), white);
}
.degrade1 {
    background: linear-gradient(to bottom, #9ca3af, gray, rgba(128, 128, 128, 0.523), gray, white);
}
.label {
    transition: 0.2s;
}
.input-text:focus ~ .label {
    position: absolute;
    top: -10px;
    background: white;
    padding: 0px 5px;
    font-size: 12px;
}
.not-empty ~ .label {
    position: absolute;
    top: -10px;
    background: white;
    padding: 0px 5px;
    font-size: 12px;
}
</style>