import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createPinia } from 'pinia';
import './assets/style.css'

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API_URL

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);
app.use(store)


app.mount('#app');