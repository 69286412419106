<!-- QRScanner.vue -->
<template>
    <div class="w-[200px] h-[200px] relative">
      <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
      
    </div>
    <button @click="openFileDialog">Importer une photo</button>
      <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" class="hidden" />
      <div v-if="message" :class="{'text-green-500': isValid, 'text-red-500': !isValid}" class="mt-4 text-xl font-bold">
        {{ message }}
      </div>
      <audio ref="successSound" src="/sounds/success.mp3"></audio>
      <audio ref="errorSound" src="/sounds/error.mp3"></audio>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import axios from 'axios';
  import { QrcodeStream } from 'vue-qrcode-reader';
  import jsQR from 'jsqr';
  
  const message = ref('');
  const isValid = ref(false);
  const fileInput = ref(null);
  const successSound = ref(null);
  const errorSound = ref(null);
  
  const onDecode = async (decodedString) => {
    try {
      const response = await axios.post(`/api/reservations/verify/3208 KU24`, { qrData: decodedString });
      if (response.data.valid) {
        message.value = 'Code QR valide!';
        isValid.value = true;
        successSound.value.play();
      } else {
        message.value = 'Code QR invalide!';
        isValid.value = false;
        errorSound.value.play();
      }
    } catch (error) {
      message.value = 'Erreur lors de la vérification du code QR!';
      isValid.value = false;
      errorSound.value.play();
    }
  };
  
  const onInit = (promise) => {
    promise.catch(error => {
      console.error(error);
      message.value = 'Erreur lors de l\'initialisation du scanner!';
      isValid.value = false;
      errorSound.value.play();
    });
  };
  
  const openFileDialog = () => {
    fileInput.value.click();
  };
  
  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const qrCode = jsQR(imageData.data, canvas.width, canvas.height);
          if (qrCode) {
            onDecode(qrCode.data);
          } else {
            message.value = 'Aucun code QR détecté!';
            isValid.value = false;
            errorSound.value.play();
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
  </script>
  
  <style scoped>
  .text-green-500 {
    color: green;
  }
  .text-red-500 {
    color: red;
  }
  .hidden {
    display: none;
  }
  </style>
  