<template>
    <div class="bg-gray-800 w-full h-[80px]">
        <HeaderComponent/>
    </div>
    <div class="w-full h-screen max-h-[550px] relative p-[10px]">
        <div class="h-full w-full rounded-[20px] overflow-hidden">
            <CarouselComponent/>
        </div>
    </div>
    <section class="hidden justify-center  items-center relative h-screen max-h-[500px]">
        <div class="w-full h-full relative">
            <img src="../assets/images/amazone2.jpg" alt="" class="w-full h-full object-cover absolute md:object-center object-left">
        </div>
        <div class="w-full h-full absolute flex flex-col justify-center items-center bg-black/60">
            <HeaderComponent/>
            <div class="container max-w-[1024px] h-full w-full relative px-[20px]">
                <div class="w-full h-full overflow-x-hidden mt-[100px] flex justify-center items-start">
                    <div class="w-full flex justify-center items-start flex-col mt-[50px] relative">
                        <span class="bg-gray-100/20 text-white text-xs p-[10px] rounded-[20px] font-bold w-auto">Ressentez l'expérience</span>
                        <h1 id="aero" class="text-white my-[20px] text-bold md:text-[60px] text-[40px] md:w-[600px] leading-none capitalize">Explorez le majestueux paysage du Bénin maintenant</h1>
                        <button class="p-[10px] flex justify-center items-center rounded-[20px] bg-white text-gray-800 font-bold text-xs uppercase px-[20px]">
                            Réserver maintenant
                            <i class="fa-solid fa-arrow-right ml-[5px] mt-[2px] text-[10px]"></i>
                        </button>
                    </div>
                    <div class="w-[50px] md:w-[80px] h-[100%] py-[20px] relative flex flex-col items-center justify-between">
                        <div class="flex justify-center items-center flex-col">
                            <h1 class="uppercase rotate-[-90deg] text-white h-[200px] w-[200px] segoe font-semibold flex justify-center items-center">
                                <div class="w-[30px] h-[30px] rounded-[50%] bg-gray-100/25 text-white flex justify-center items-center cursor-pointer m-[5px] text-sm"><i class="rotate-[90deg] fa-brands fa-facebook"></i></div>
                                <div class="w-[30px] h-[30px] rounded-[50%] bg-gray-100/25 text-white flex justify-center items-center cursor-pointer m-[5px] text-sm"><i class="rotate-[90deg] fa-brands fa-twitter"></i></div>
                                <div class="w-[30px] h-[30px] rounded-[50%] bg-gray-100/25 text-white flex justify-center items-center cursor-pointer m-[5px] text-sm"><i class="rotate-[90deg] fa-brands fa-instagram"></i></div>
                                <div class="w-[30px] h-[30px] rounded-[50%] bg-gray-100/25 text-white flex justify-center items-center cursor-pointer m-[5px] text-sm"><i class="rotate-[90deg] fa-brands fa-tiktok"></i></div>
                            </h1>
                            <div class="w-[200px] h-[200px] left-[-100px] md:w-[150px] md:h-[150px] md:left-[-73px]  rotate-[-90deg] relative border-b-2 my-[0px] border-white">
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-[50px] flex flex-col justify-center items-center relative">
        <div class="container max-w-[1024px] px-[20px] hidden flex-col justify-center items-center w-full relative">
            <h1 class="md:text-4xl text-xl font-bold text-gray-800 mb-[50px] text-left w-full">Les 4 prochains voyages </h1>
            <div class="w-full relative flex flex-wrap justify-center items-center">
                <div class="w-full lg:w-[calc(100%/4-20px)] md:w-[calc(100%/2-40px)] h-[150px] lg:mx-[10px] md:mx-[20px] my-[10px] rounded-[20px] bg-white p-[10px] shadow-4xl flex relative">
                    <div class="w-[40%] h-full rounded-[10px] flex relative justify-center items-center overflow-hidden">
                        <img src="../assets/images/about.jpg" alt="" class="w-full h-full absolute object-cover">
                    </div>
                    <div class="w-[60%] flex flex-col px-[10px]">
                        <h1 class="text-sm font-bold text-gray-800">Nantitingou</h1>
                        <h2 class="text-xs font-semibold text-gray-400">Depuis Cotonou</h2>
                        <div class="w-full mt-[5px]">
                            <h1 class="text-xl font-bold text-gray-800"><span class="text-xs text-gray-500">FCFA</span> 7000</h1>
                            <p class="text-[10px] mt-[-5px] text-gray-500 font-bold">
                                22 jui 2024, 14h30
                            </p>
                            <button @click="goToConfirmationPage()" class="text-gray-100 mt-[10px] font-bold bg-gray-800 w-full rounded-[5px] px-[10px] py-[5px] text-sm">Réserver</button>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-[calc(100%/4-20px)] md:w-[calc(100%/2-40px)] h-[150px] lg:mx-[10px] md:mx-[20px] my-[10px] rounded-[20px] bg-white p-[10px] shadow-4xl flex relative">
                    <div class="w-[40%] h-full rounded-[10px] flex relative justify-center items-center overflow-hidden">
                        <img src="../assets/images/about.jpg" alt="" class="w-full h-full absolute object-cover">
                    </div>
                    <div class="w-[60%] flex flex-col px-[10px]">
                        <h1 class="text-sm font-bold text-gray-800">Nantitingou</h1>
                        <h2 class="text-xs font-semibold text-gray-400">Depuis Cotonou</h2>
                        <div class="w-full mt-[5px]">
                            <h1 class="text-xl font-bold text-gray-800"><span class="text-xs text-gray-500">FCFA</span> 7000</h1>
                            <p class="text-[10px] mt-[-5px] text-gray-500 font-bold">
                                22 juillet 2024
                            </p>
                            <button class="text-gray-200 mt-[10px] font-bold bg-gray-800 w-full rounded-[5px] px-[10px] py-[5px] text-sm">Réserver</button>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-[calc(100%/4-20px)] md:w-[calc(100%/2-40px)] h-[150px] lg:mx-[10px] md:mx-[20px] my-[10px] rounded-[20px] bg-white p-[10px] shadow-4xl flex relative">
                    <div class="w-[40%] h-full rounded-[10px] flex relative justify-center items-center overflow-hidden">
                        <img src="../assets/images/about.jpg" alt="" class="w-full h-full absolute object-cover">
                    </div>
                    <div class="w-[60%] flex flex-col px-[10px]">
                        <h1 class="text-sm font-bold text-gray-800">Nantitingou</h1>
                        <h2 class="text-xs font-semibold text-gray-400">Depuis Cotonou</h2>
                        <div class="w-full mt-[5px]">
                            <h1 class="text-xl font-bold text-gray-800"><span class="text-xs text-gray-500">FCFA</span> 7000</h1>
                            <p class="text-[10px] mt-[-5px] text-gray-500 font-bold">
                                22 juillet 2024
                            </p>
                            <button class="text-gray-200 mt-[10px] font-bold bg-gray-800 w-full rounded-[5px] px-[10px] py-[5px] text-sm">Réserver</button>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-[calc(100%/4-20px)] md:w-[calc(100%/2-40px)] h-[150px] lg:mx-[10px] md:mx-[20px] my-[10px] rounded-[20px] bg-white p-[10px] shadow-4xl flex relative">
                    <div class="w-[40%] h-full rounded-[10px] flex relative justify-center items-center overflow-hidden">
                        <img src="../assets/images/about.jpg" alt="" class="w-full h-full absolute object-cover">
                    </div>
                    <div class="w-[60%] flex flex-col px-[10px]">
                        <h1 class="text-sm font-bold text-gray-800">Nantitingou</h1>
                        <h2 class="text-xs font-semibold text-gray-400">Depuis Cotonou</h2>
                        <div class="w-full mt-[5px]">
                            <h1 class="text-xl font-bold text-gray-800"><span class="text-xs text-gray-500">FCFA</span> 7000</h1>
                            <p class="text-[10px] mt-[-5px] text-gray-500 font-bold">
                                22 juillet 2024
                            </p>
                            <button class="text-gray-200 mt-[10px] font-bold bg-gray-800 w-full rounded-[5px] px-[10px] py-[5px] text-sm">Réserver</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isLoading" class="container max-w-[1024px] px-[20px] flex flex-col justify-center items-center w-full relative">
            <h1 class="w-full text-gray-800 md:text-3xl text-xl font-bold mb-[25px]">Quelques voyages sur le point de démarrer ...</h1>
            <div class="w-full relative flex flex-col md:flex-row justify-start items-center md:justify-between">
                <div class="lg:w-[calc(100%/4-20px)] md:w-[calc(100%/2-20px)] w-full my-2 flex md:flex-col flex-row">
                    <div class="w-full md:h-[125px] h-[100px] rounded-2xl bg-gray-200 overflow-hidden flex justify-center items-center relative shimmer"></div>
                    <div class="w-full relative md:py-2 md:pl-0 pl-2">
                        <div class="w-full relative flex justify-between flex-col md:flex-row md:items-center">
                            <h1 class="md:text-sm font-bold text-gray-500 bg-gray-200 w-[170px] h-6 rounded shimmer"></h1>
                            <h1 class="md:text-sm font-bold text-gray-800 bg-gray-200 mt-[5px] md:mt-0 w-[50px] h-6 rounded shimmer"></h1>
                        </div>
                        <p class="md:text-xs text-sm font-semibold text-gray-500 mt-1 bg-gray-200 w-full h-5 rounded shimmer"></p>
                    </div>
                </div>
                <div class="lg:w-[calc(100%/4-20px)] md:w-[calc(100%/2-20px)] w-full my-2 flex md:flex-col flex-row">
                    <div class="w-full md:h-[125px] h-[100px] rounded-2xl bg-gray-200 overflow-hidden flex justify-center items-center relative shimmer"></div>
                    <div class="w-full relative md:py-2 md:pl-0 pl-2">
                        <div class="w-full relative flex justify-between flex-col md:flex-row md:items-center">
                            <h1 class="md:text-sm font-bold text-gray-500 bg-gray-200 w-[170px] h-6 rounded shimmer"></h1>
                            <h1 class="md:text-sm font-bold text-gray-800 bg-gray-200 mt-[5px] md:mt-0 w-[50px] h-6 rounded shimmer"></h1>
                        </div>
                        <p class="md:text-xs text-sm font-semibold text-gray-500 mt-1 bg-gray-200 w-full h-5 rounded shimmer"></p>
                    </div>
                </div>
                <div class="lg:w-[calc(100%/4-20px)] md:w-[calc(100%/2-20px)] w-full my-2 md:flex hidden md:flex-col flex-row">
                    <div class="w-full md:h-[125px] h-[100px] rounded-2xl bg-gray-200 overflow-hidden flex justify-center items-center relative shimmer"></div>
                    <div class="w-full relative md:py-2 md:pl-0 pl-2">
                        <div class="w-full relative flex justify-between flex-col md:flex-row md:items-center">
                            <h1 class="md:text-sm font-bold text-gray-500 bg-gray-200 w-[170px] h-6 rounded shimmer"></h1>
                            <h1 class="md:text-sm font-bold text-gray-800 bg-gray-200 w-[50px] h-6 rounded shimmer"></h1>
                        </div>
                        <p class="md:text-xs text-sm font-semibold text-gray-500 mt-1 bg-gray-200 w-full h-5 rounded shimmer"></p>
                    </div>
                </div>
                <div class="lg:w-[calc(100%/4-20px)] md:w-[calc(100%/2-20px)] w-full my-2 md:flex hidden md:flex-col flex-row">
                    <div class="w-full md:h-[125px] h-[100px] rounded-2xl bg-gray-200 overflow-hidden flex justify-center items-center relative shimmer"></div>
                    <div class="w-full relative md:py-2 md:pl-0 pl-2">
                        <div class="w-full relative flex justify-between flex-col md:flex-row md:items-center">
                            <h1 class="md:text-sm font-bold text-gray-500 bg-gray-200 w-[170px] h-6 rounded shimmer"></h1>
                            <h1 class="md:text-sm font-bold text-gray-800 bg-gray-200 w-[50px] h-6 rounded shimmer"></h1>
                        </div>
                        <p class="md:text-xs text-sm font-semibold text-gray-500 mt-1 bg-gray-200 w-full h-5 rounded shimmer"></p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isLoading" class="container max-w-[1024px] px-[20px] flex flex-col justify-center items-center w-full relative">
            <h1 class="w-full text-gray-800 md:text-3xl text-xl font-bold mb-[25px]">Quelques voyages sur le point de démarrer ...</h1>
            <div class="w-full relative flex flex-col md:flex-row justify-start items-center md:justify-between">
                <router-link v-for="(trajet, index) in trajets" :key="index" class="lg:w-[calc(100%/4-20px)] cursor-pointer md:w-[calc(100%/2-20px)] w-[calc(100%)] my-[10px] flex md:flex-col flex-row" :to="'/confirmation/reservation/'+ trajet._id">
                    <div class="w-full md:h-[125px] h-[110px] rounded-[20px] overflow-hidden flex justify-center items-center relative">
                        <img v-if="trajet.destination == 'Parakou'" src="../assets/images/parakou.jpg" alt="" class="w-full h-full absolute object-cover">
                        <img v-else-if="trajet.destination == 'Natitingou'" src="../assets/images/nati.jpg" alt="" class="w-full h-full absolute object-cover">
                        <img v-else-if="trajet.destination == 'Porto Novo'" src="../assets/images/porto-novo.jpg" alt="" class="w-full h-full absolute object-cover">
                        <img v-else-if="trajet.destination == 'Bantè'" src="../assets/images/bante.jpeg" alt="" class="w-full h-full absolute object-cover">
                        <img v-else-if="trajet.destination == 'Grand Popo'" src="../assets/images/grand-popo.jpg" alt="" class="w-full h-full absolute object-cover">
                        <img v-else-if="trajet.destination == 'Ouidah'" src="../assets/images/ouidah.jpg" alt="" class="w-full h-full absolute object-cover">
                        <img v-else-if="trajet.destination == 'Calavi' || trajet.destination == 'Abomey Calavi'" src="../assets/images/calavi.jpg" alt="" class="w-full h-full absolute object-cover">
                        <img v-else-if="trajet.destination == 'Bohicon'" src="../assets/images/bohicon.jpg" alt="" class="w-full h-full absolute object-cover">
                        <img v-else src="../assets/images/indisponible.jpg" alt="" class="w-full h-full absolute object-left-top object-cover">
                    </div>
                    <div class="w-full relative md:py-[10px] pl-[10px] md:pl-0">
                        <div class="w-full relative flex justify-between flex-col md:flex-row md:items-center">
                            <h1 class="md:text-sm font-bold text-gray-500">{{ trajet.depart }} - {{ trajet.destination }}</h1>
                            <h1 class="md:text-sm font-bold text-gray-800">{{ trajet.prix_reduit }} FCFA</h1>
                        </div>
                        <p class="md:text-xs text-sm font-semibold text-gray-500 mt-[5px]">
                            {{ trajet.heureDepart }} à {{ getHeureDestination(trajet.heureDepart, trajet.duree) }} <span class="ml-[5px]" v-if="trajet.escale.length === 0">-</span> <span class="ml-[5px]" v-else>{{ trajet.escale.length }}</span> escales
                        </p>
                        <div class="flex w-full justify-start mt-[5px] items-center">
                            <div class="w-[30px] h-[30px] rounded-[50%] shadow-4xl overflow-hidden flex justify-center items-center relative">
                                <img v-if="trajet.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="trajet.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="trajet.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="trajet.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <h1 class="text-sm font-bold text-gray-500 mx-[5px]">{{ trajet.nomCompagnie }}</h1>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
    <section class="pb-[50px] flex flex-col justify-center items-center relative">
        <div class="container max-w-[1024px] relative px-[20px] flex justify-center items-center">
            <div class="w-full flex justify-center items-center relative flex-col lg:flex-row">
                <div class="lg:w-5/12 w-full lg:h-[400px] lg:pr-[40px]">
                    <h1 class="text-4xl font-bold text-gray-900 capitalize">Expérimentez une nouvelle aventure</h1>
                    <p class="text-gray-600 text-sm my-[10px]">
                        Découvrez pourquoi notre plateforme est le choix idéal pour vos voyages en bus au Bénin.
                    </p>
                    <div class="flex flex-row lg:flex-col justify-center items-center flex-wrap">
                        <div class="mx-[10px] lg:mx-0 my-[10px] md:w-[300px] w-full flex lg:w-full relative items-center">
                            <div class="w-[35px] min-w-[35px] h-[35px] bg-gray-800 rounded-[50%] text-center flex justify-center items-center text-white">
                                <i class="fas fa-calendar-check text-sm"></i>
                            </div>
                            <div class="ml-[10px]">
                                <h1 class="text-sm font-bold text-gray-800">Facilité de Réservation</h1>
                                <p class="text-sm text-gray-600">
                                    Réservez vos trajets en quelques clics seulement, sans tracas ni complications.
                                </p>
                            </div>
                        </div>
                        <div class="mx-[10px] lg:mx-0 my-[10px] md:w-[300px] w-full flex lg:w-full relative items-center">
                            <div class="w-[35px] min-w-[35px] h-[35px] bg-gray-800 rounded-[50%] text-center flex justify-center items-center text-white">
                                <i class="fas fa-gift text-sm"></i>
                            </div>
                            <div class="ml-[10px]">
                                <h1 class="text-sm font-bold text-gray-800">Offres et Réductions</h1>
                                <p class="text-sm text-gray-600">
                                    Profitez de réductions exclusives sur vos trajets pour économiser à chaque voyage.
                                </p>
                            </div>
                        </div>
                        <div class="mx-[10px] lg:mx-0 my-[10px] md:w-[300px] w-full flex lg:w-full relative items-center">
                            <div class="w-[35px] min-w-[35px] h-[35px] bg-gray-800 rounded-[50%] text-center flex justify-center items-center text-white">
                                <i class="fas fa-headset text-sm"></i>
                            </div>
                            <div class="ml-[10px]">
                                <h1 class="text-sm font-bold text-gray-800">Support Client 24/7</h1>
                                <p class="text-sm text-gray-600">
                                    Notre équipe est disponible à tout moment pour répondre à vos questions et vous assister.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lg:w-7/12 w-full lg:h-[400px] h-[300px] mt-[20px] lg:mt-0 rounded-[20px] overflow-hidden z-10 relative">
                    <img src="../assets/images/pourquoi.jpg" alt="" class="w-full h-full absolute object-cover object-center z-[-10]">
                </div>
            </div>
        </div>
    </section>
    <section class="py-[50px] relative w-full bg-gray-100 flex flex-col justify-center items-center">
        <h1 class="text-center text-gray-800 md:text-2xl font-bold">Nos partenaires</h1>
        <p class="text-sm font-semibold text-gray-500 text-center px-[20px] my-[10px]">
            Nous collaborons avec les meilleures compagnies de bus du Bénin pour vous offrir des trajets sûrs, confortables et fiables.
        </p>
        <button class="px-[20px] py-[10px] text-sm font-bold text-gray-800 border border-gray-800 rounded-[5px]"><i class="fa-solid fa-people-group mr-[5px]"></i> Devenir partenaire</button>
        <div class="container max-w-[1024px] px-[20px] mt-[50px] w-full relative">
            <div class="w-full relative flex justify-center items-center">
                <CarouselCompagnie/>
            </div>
        </div>
    </section>
    <section class="my-[50px] md:h-[400px] h-[700px] flex justify-center items-center relative">
        <div class="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex lg:flex-row flex-col">
            <div class="lg:w-6/12 w-full md:h-[400px] h-[350px] bg-gray-800"></div>
            <div class="lg:w-6/12 w-full md:h-[400px] h-[350px] bg-white"></div>
        </div>
        <div class="w-full top-0 absolute flex justify-center items-center px-[20px] pt-0 max-w-[1024px] lg:flex-row flex-col">
            <div class="lg:w-6/12 w-full md:h-[400px] h-[350px] pt-[80px] lg:pr-[50px] text-white">
                <h1 class="md:text-3xl text-2xl capitalize font-bold">#1 Votre unique partenaire pour les meilleurs parcours en bus au Bénin </h1>
                <p class="text-sm text-gray-100 mt-[20px]">
                    En collaboration avec nos compagnies partenaires 
                    de confiance, nous garantissons des trajets confortables, sécurisés. Notre 
                    plateforme simplifie la réservation de bus en quelques clics. Faites de nous votre 
                    partenaire privilégié pour des voyages inoubliables en bus.
                </p>
            </div>
            <div class="lg:w-6/12 w-full md:h-[400px] h-[350px] overflow-hidden flex justify-center items-center relative">
                <img src="../assets/images/bet.jpg" alt="" class="h-full absolute object-contain object-center">
            </div>
        </div>
    </section>
    <section class="w-full my-[50px] bg-gray-100 py-[50px] flex justify-center items-center relative">
        <div class="container max-w-[1024px] px-[20px] w-full flex-col relative flex justify-center ">
            <h1 class="text-center text-gray-800 md:text-2xl font-bold">
                Comment ça marche ?
            </h1>
            <div class="w-full relative flex justify-end items-center md:hidden mt-[50px] mb-[20px]">
                <button id="scroll-left" class="w-[30px] h-[30px] mr-[15px] rounded-[50%] bg-gray-800 text-white flex justify-center items-center text-sm font-bold">
                    <i class="fa-solid fa-chevron-left"></i>
                </button>
                <button id="scroll-right" class="w-[30px] h-[30px] rounded-[50%] bg-gray-800 text-white flex justify-center items-center text-sm font-bold">
                    <i class="fa-solid fa-chevron-right"></i>
                </button>
            </div>
            <div id="parent" class="md:mt-[50px] flex flex-col-reverse md:flex-row parent justify-start overflow-x-auto items-center w-full md:h-[850px]">
                <div class="w-full h-full flex md:flex-col">
                    <div class="md:w-full w-[300px] pl-[25px] md:pl-0 min-w-[300px] h-[150px] flex md:justify-end justify-start md:items-center items-start">
                        <div class="h-[100px] w-[100px] flex justify-center items-center overflow-hidden relative">
                            <img src="../assets/images/travel.png" alt="" class="w-full h-full absolute object-contain">
                        </div>
                    </div>
                    <div class="h-[150px] w-[300px] min-w-[300px] md:w-full flex flex-col items-end">
                        <div class="w-[30px] h-[30px] rounded-[50%] bg-blue-500 text-white text-sm font-bold flex justify-center items-center">
                            2
                        </div>
                        <h1 class="text-gray-800 font-bold text-sm my-[15px]">
                            Choisissez le Trajet Parfait
                        </h1>
                        <p class="text-xs text-right text-gray-500 font-semibold">
                            Parcourez les résultats de recherche et sélectionnez le trajet qui répond à vos besoins en termes de horaires, de prix et de confort. Cliquez sur 'Réserver' pour continuer.
                        </p>
                    </div>
                    <div class="md:w-full w-[300px] pl-[25px] md:pl-0 min-w-[300px] h-[150px] flex md:justify-end justify-start md:items-center items-start">
                        <div class="h-[100px] w-[100px] flex justify-center items-center overflow-hidden relative">
                            <img src="../assets/images/id-card.png" alt="" class="w-full h-full absolute object-contain">
                        </div>
                    </div>
                    <div class="h-[150px] w-[300px] min-w-[300px] md:w-full flex flex-col items-end">
                        <div class="w-[30px] h-[30px] rounded-[50%] bg-blue-500 text-white text-sm font-bold flex justify-center items-center">
                            4
                        </div>
                        <h1 class="text-gray-800 font-bold text-sm my-[15px]">
                            Procédez au Paiement
                        </h1>
                        <p class="text-xs text-right text-gray-500 font-semibold">
                            Choisissez votre méthode de paiement préférée et complétez la transaction en toute sécurité. Nous acceptons plusieurs modes de paiement pour votre commodité.
                        </p>
                    </div>
                    <div class="md:w-full w-[300px] pl-[25px] md:pl-0 min-w-[300px] h-[150px] flex md:justify-end justify-start md:items-center items-start">
                        <div class="h-[100px] w-[100px] flex justify-center items-center overflow-hidden relative">
                            <img src="../assets/images/credit-card.png" alt="" class="w-full h-full absolute object-contain">
                        </div>
                    </div>
                </div>
                <div class="md:w-[70px] md:h-full h-[70px] min-h-[70px] w-[3000px] md:min-w-[70px] md:flex-col flex justify-center items-center">
                    <div class="md:h-full w-full md:w-auto md:border-l-4 border-b-4 md:border-b-0 border-gray-300">

                    </div>
                    <button @click="goToReservationPage()" class="border md:w-[180px] bg-blue-600 p-[10px] rounded-[5px] font-bold text-sm text-white">
                        Réserver maintenant
                    </button>
                </div>
                <div class="w-full h-full flex md:flex-col">
                    <div class="h-[150px] w-[300px] min-w-[300px] md:w-full">
                        <div class="w-[30px] h-[30px] rounded-[50%] bg-blue-500 text-white text-sm font-bold flex justify-center items-center">
                            1
                        </div>
                        <h1 class="text-gray-800 font-bold text-sm my-[15px]">
                            Trouvez votre Trajet Idéal
                        </h1>
                        <p class="text-xs text-gray-500 font-semibold">
                            Utilisez notre barre de recherche pour trouver des trajets en bus en fonction de votre destination, de la date de départ, et d'autres critères. Comparez les options pour choisir celle qui vous convient le mieux.
                        </p>
                    </div>
                    <div class="md:w-full h-[150px] w-[300px] min-w-[300px] flex justify-end pr-[25px] md:pr-0 md:justify-start md:items-center items-end">
                        <div class="h-[100px] w-[100px] flex justify-center items-center overflow-hidden relative">
                            <img src="../assets/images/school.png" alt="" class="w-full h-full absolute object-contain">
                        </div>
                    </div>
                    <div class="h-[150px] w-[300px] min-w-[300px] md:w-full">
                        <div class="w-[30px] h-[30px] rounded-[50%] bg-blue-500 text-white text-sm font-bold flex justify-center items-center">
                            3
                        </div>
                        <h1 class="text-gray-800 font-bold text-sm my-[15px]">
                            Entrez les Détails des Passagers
                        </h1>
                        <p class="text-xs text-gray-500 font-semibold">
                            Remplissez les informations nécessaires pour chaque passager, telles que le nom, l'âge, et le numéro de contact. Assurez-vous que toutes les informations sont correctes pour éviter tout problème lors de l'embarquement.
                        </p>
                    </div>
                    <div class="md:w-full h-[150px] w-[300px] min-w-[300px] flex justify-end pr-[25px] md:pr-0 md:justify-start md:items-center items-end">
                        <div class="h-[100px] w-[100px] flex justify-center items-center overflow-hidden relative">
                            <img src="../assets/images/online-booking.png" alt="" class="w-full h-full absolute object-contain">
                        </div>
                    </div>
                    <div class="h-[150px] w-[300px] min-w-[300px] md:w-full">
                        <div class="w-[30px] h-[30px] rounded-[50%] bg-blue-500 text-white text-sm font-bold flex justify-center items-center">
                            5
                        </div>
                        <h1 class="text-gray-800 font-bold text-sm my-[15px]">
                            Confirmation de Réservation
                        </h1>
                        <p class="text-xs text-gray-500 font-semibold">
                            Une fois le paiement effectué, vous recevrez une confirmation de réservation par email avec tous les détails de votre trajet. Gardez cet email précieusement pour le jour de votre voyage.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="my-[50px] py-[50px] w-full flex justify-center items-center relative">
        <div class="container max-w-[1024px] px-[20px] w-full relative flex flex-col justify-center ">
            <h1 class="text-center text-sm font-bold md:text-2xl text-gray-800 mb-[50px]">Les internautes donnent leur avis 🥰 !</h1>
            <div class="w-full relative flex justify-center items-center">
                <CarouselAvis/>
            </div>
        </div>
    </section>
    
    
    <FooterComponent/>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue"
import FooterComponent from "@/components/FooterComponent.vue"
import CarouselComponent from "@/components/CarouselComponent.vue"
import CarouselCompagnie from "@/components/CarouselCompagnie.vue";
import CarouselAvis from '@/components/CarouselAvis.vue'
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import $ from 'jquery'
import axios from 'axios'

const router = useRouter()

function goToConfirmationPage() {
    router.push('/confirmation/reservation/1234-552')
}
function goToReservationPage() {
    router.push('/reservation')
}  

const isLoading = ref(false)
const trajets = ref([])
const fetchTrajets = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(`/api/trajets`);
    const now = new Date();

    // Filtrer les trajets à venir et ceux déjà passés
    const upcomingTrajets = [];
    const pastTrajets = [];

    response.data.forEach(trajet => {
      const [hours, minutes] = trajet.heureDepart.split(':').map(Number);
      const departureTime = new Date();
      departureTime.setHours(hours, minutes, 0, 0);

      if (departureTime > now) {
        upcomingTrajets.push(trajet);
      } else {
        pastTrajets.push(trajet);
      }
    });

    // Trier les trajets par heure de départ
    upcomingTrajets.sort((a, b) => {
      const [hoursA, minutesA] = a.heureDepart.split(':').map(Number);
      const [hoursB, minutesB] = b.heureDepart.split(':').map(Number);
      const timeA = new Date();
      const timeB = new Date();
      timeA.setHours(hoursA, minutesA, 0, 0);
      timeB.setHours(hoursB, minutesB, 0, 0);
      return timeA - timeB;
    });

    pastTrajets.sort((a, b) => {
      const [hoursA, minutesA] = a.heureDepart.split(':').map(Number);
      const [hoursB, minutesB] = b.heureDepart.split(':').map(Number);
      const timeA = new Date();
      const timeB = new Date();
      timeA.setHours(hoursA, minutesA, 0, 0);
      timeB.setHours(hoursB, minutesB, 0, 0);
      return timeA - timeB;
    });

    // Combiner les trajets pour atteindre 4 trajets
    const combinedTrajets = [...upcomingTrajets, ...pastTrajets].slice(0, 4);
    trajets.value = combinedTrajets;
  } catch (err) {
    console.log('')
  } finally {
    isLoading.value = false;
  }
};

const getHeureDestination = (heureDepart, duree) => {
  if (!heureDepart || duree === undefined) return ''; // Vérifiez si les données sont disponibles

  const [hours, minutes] = heureDepart.split(':').map(Number);
  const departDate = new Date();
  departDate.setHours(hours, minutes, 0, 0);

  // Convertir la durée en minutes
  const dureeMinutes = duree * 60;

  // Ajouter la durée en minutes
  departDate.setMinutes(departDate.getMinutes() + dureeMinutes);

  const destinationHours = String(departDate.getHours()).padStart(2, '0');
  const destinationMinutes = String(departDate.getMinutes()).padStart(2, '0');

  return `${destinationHours}:${destinationMinutes}`;
};

onMounted(() => {
    $("#scroll-left").on("click", function () {
      $("#parent").animate({ scrollLeft: "-=300" }, "slow");
    });

    // Défilement vers la droite (en avant)
    $("#scroll-right").on("click", function () {
      $("#parent").animate({ scrollLeft: "+=300" }, "slow");
    });
    
    fetchTrajets()
})
</script>
<style scoped>
@import 'swiper/swiper-bundle.css';
@import 'swiper/css';
@import 'swiper/css/autoplay';
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
#flech::after {
    content: '';
    border-style: solid;
    border-width: 10px;
    border-color: #1f2937 transparent transparent transparent;
    position: absolute;
    bottom: -20px;
    left: 25px;
}
</style>
