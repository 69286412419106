<template>
    <swiper
      :modules="modules"
      :slides-per-view="getSlidesPerView"
      :breakpoints="{
          1024: {
            slidesPerView: 4
          },
          0: {
            slidesPerView: getSlidesPerView
          }
      }"
      :loop="true"
      :autoplay="{ delay: 2000, disableOnInteraction: false }"
      :pagination="{ clickable: true }"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index" class="pb-[35px]">
        <div class="w-[130px] min-w-[130px] h-[120px] min-h-[120px] md:m-[20px] m-[10px] rounded-[20px] bg-white shadow-6xl p-[20px] flex justify-center items-center flex-col">
          <div class="w-[50px] h-[50px] bg-white shadow-4xl flex justify-center relative items-center overflow-x-hidden">
            <img :src="slide.image" alt="" class="w-full h-full absolute object-contain">
          </div>
          <h1 class="mt-[10px] font-bold text-gray-800 text-sm">{{ slide.title }}</h1>
        </div>
      </swiper-slide>
    </swiper>
</template>
  
<script>
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      slides: [
        { image: './img/att.jpeg', title: 'ATT' },
        { image: './img/confort.jpeg', title: 'Confort Lines' },
        { image: './img/youniti.jpeg', title: 'Youniti' },
        { image: './img/la-poste.jpg', title: 'La poste' },
      ],
    };
  },
  computed: {
    getSlidesPerView() {
      const screenWidth = window.innerWidth;
      return Math.floor(screenWidth / 150); // Adjusted for padding and margins
    },
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, A11y],
    };
  },
};
</script>

<style scoped>
/* Add any additional styles here */
.mySwiper .swiper-pagination {
  bottom: 20px; /* Adjust this value to move the pagination bullets up or down */
}
</style>
