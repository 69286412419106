<template>
    <swiper
        :modules="modules"
        :slides-per-view="1"
        :loop="true"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
        :pagination="{ clickable: true }"
        :scrollbar="{ draggable: true }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
    >
        <swiper-slide v-for="(slide, index) in slides" :key="index">
            <section class="flex w-full justify-center items-center relative h-screen max-h-[600px]">
                <div class="w-full h-full relative">
                    <img :src="slide.image" alt="" class="w-full h-full object-cover absolute md:object-center object-left">
                </div>
                <div class="w-full h-full absolute flex flex-col justify-center items-center bg-black/60">
                    <div class="container max-w-[1024px] h-full w-full relative px-[20px]">
                        <div class="w-full h-full overflow-x-hidden mt-[100px] flex justify-center items-start">
                            <div class="w-full flex justify-center items-start flex-col mt-[50px] relative">
                                <span class="bg-gray-100/20 text-white text-xs p-[10px] rounded-[20px] font-bold w-auto">{{ slide.tagline }}</span>
                                <h1 class="text-white my-[20px] text-bold md:text-[60px] text-[40px] md:w-[600px] leading-none capitalize">{{ slide.title }}</h1>
                                <button @click="goToReservationPage()" class="p-[10px] flex justify-center items-center rounded-[20px] bg-white text-gray-800 font-bold text-xs uppercase px-[20px]">
                                    Réserver maintenant
                                    <i class="fa-solid fa-arrow-right ml-[5px] mt-[2px] text-[10px]"></i>
                                </button>
                            </div>
                            <div class="w-[50px] md:w-[80px] h-full py-[20px] relative flex flex-col items-center justify-between">
                                <div class="flex justify-center items-center flex-col">
                                    <div class="flex flex-col items-center">
                                        <div v-for="icon in slide.socialIcons" :key="icon" class="w-[30px] h-[30px] rounded-full bg-gray-100/25 text-white flex justify-center items-center cursor-pointer m-[5px] text-sm">
                                            <i :class="icon"></i>
                                        </div>
                                    </div>
                                    <div class="w-[200px] h-[200px] left-[-100px] md:w-[150px] md:h-[150px] md:left-[-73px] rotate-[-90deg] relative border-b-2 my-[0px] border-white"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </swiper-slide>
    </swiper>
</template>
  
<script>
import { useRouter } from 'vue-router'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay'

const router = useRouter()

function goToReservationPage() {
    router.push('/reservation')
}  

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            slides: [
                {
                    image: './img/shuttle-bus-brought-people_587448-5608 (1).jpg',
                    tagline: 'Ressentez l\'expérience',
                    title: 'Explorez le majestueux paysage du Bénin maintenant',
                    buttonText: 'Réserver maintenant',
                    socialIcons: [
                        'fa-brands fa-facebook',
                        'fa-brands fa-twitter',
                        'fa-brands fa-instagram',
                        'fa-brands fa-tiktok',
                    ],
                },
                {
                    image: './img/branding.jpg',
                    tagline: 'La poste Bénin',
                    title: 'Profitez de notre promotion de -15% maintenant',
                    buttonText: 'Réserver maintenant',
                    socialIcons: [
                        'fa-brands fa-facebook',
                        'fa-brands fa-twitter',
                        'fa-brands fa-instagram',
                        'fa-brands fa-tiktok',
                    ],
                },
            ],
        };
    },
    setup() {
        const onSwiper = (swiper) => {};
        const onSlideChange = () => {};
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
        };
    },
};
</script>

<style scoped>
</style>
