<template>
    <section class="flex justify-center h-screen items-center w-full relative">
        <div class="container w-full h-full relative flex justify-center items-center">
            <div class="w-6/12 flex justify-center items-center h-full bg-white"></div>
            <div class="w-6/12 flex justify-center items-center h-full bg-blue-200">
                <img src="../assets/images/illustration.png" alt="">
            </div>
        </div>
    </section>
</template>

<script setup>

</script>

<style>

</style>