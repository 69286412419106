<template>
    <section class="flex justify-center  items-center relative h-screen max-h-[500px]">
        <div class="w-full h-full relative">
            <img src="../assets/images/1628627 (1).jpg" alt="" class="w-full h-full object-cover absolute object-center">
        </div>
        <div class="w-full h-full absolute flex flex-col justify-center items-center z-10 bg-black/30">
            <HeaderComponent/>
                <div class="container max-w-[900px] h-full w-full relative px-[25px] py-[50px]">
                    <div class="w-full h-full flex flex-col justify-end items-center relative">
                        <h1 id="aero" class="text-center text-white mb-[25px] md:mb-[50px] text-3xl md:text-5xl">Trajets en bus</h1>
                        <div class="w-full p-[25px] bg-gray-800 rounded-[10px]">
                            <div class="flex justify-start items-center">
                                <div class="relative max-w-[150px] w-auto">
                                    <select v-model="search.type" class="w-full text-gray-300 text-sm bg-transparent border border-none rounded-md px-[30px] py-2 pr-8 outline-none">
                                        <option value="" class="bg-gray-900 text-gray-300 text-sm">Aller-simple</option>
                                    </select>
                                    <div class="pointer-events-none text-xs absolute inset-y-0 left-0 flex items-center px-2 text-gray-300">
                                        <i class="fas fa-right-left"></i>
                                    </div>
                                </div>
                                <div class="relative max-w-[80px] mx-[25px] w-auto">
                                    <select v-model="search.passengers" class="w-full text-gray-300 text-sm bg-transparent border border-none rounded-md px-[30px] py-2 pr-8 focus:outline-none">
                                        <option value="1" class="bg-gray-900 text-gray-300 text-sm">1</option>
                                    </select>
                                    <div class="pointer-events-none text-xs absolute inset-y-0 left-0 flex items-center px-2 text-gray-300">
                                        <i class="fas fa-user"></i>
                                    </div>
                                </div>
                                <div class="relative w-auto flex">
                                    <i class="fas fa-wifi text-xs text-gray-300"></i>
                                    <input type="checkbox" v-model="search.wifi" class="mx-[10px]">
                                </div>
                            </div>
                            <div class="relative flex flex-col md:flex-row justify-center items-center">
                                <div class="md:w-[60%] my-[10px] md:pr-[10px] relative w-full">
                                    <div class="relative flex justify-between">
                                        <div class="w-[calc(50%-8px)] relative border-[0.5px] border-gray-400 rounded-[5px]">
                                            <label for=""><i class="fas fa-road text-xs text-gray-300 top-[11px] left-[8px] absolute"></i></label>
                                            <input type="text" v-model="search.depart" class="w-full p-[8px] border-none bg-transparent outline-none text-sm text-white pl-[35px]" placeholder="Départ">
                                        </div>
                                        <div class="w-[30px] h-[30px] bg-gray-800 z-10 rounded-[50%] border-[0.5px] border-gray-400 absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] flex justify-center items-center"><i class="fas fa-right-left text-xs z-30 text-gray-300"></i></div>
                                        <div class="w-[16px] h-[40px] bg-gray-800 z-20 absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] flex justify-center items-center"></div>
                                        <div class="bg-gray-900 z-30 absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] flex justify-center items-center"><i class="fas fa-right-left text-xs text-gray-300"></i></div>
                                        <div class="w-[calc(50%-8px)] relative border-[0.5px] border-gray-400 rounded-[5px]">
                                            <label for=""><i class="fas fa-location-dot text-xs text-gray-300 top-[11px] left-[13px] absolute"></i></label>
                                            <input type="text" v-model="search.destination" class="w-full p-[8px] border-none bg-transparent outline-none text-sm text-white pl-[35px]" placeholder="Destination">
                                        </div>
                                    </div>
                                </div>
                                <div class="md:w-[60%] my-[10px] md:pl-[10px] relative w-full">
                                    <div class="border-[0.5px] relative flex border-gray-400 text-gray-300 rounded-[5px]">
                                    <div class="relative w-full">
                                        <input type="text" v-model="search.compagnie" class="w-full bg-transparent text-gray-300 text-sm placeholder:text-gray-300 rounded-md p-[8px] outline-none" placeholder="Nom de la compagnie">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="flex justify-center py-[50px] items-center flex-col relative">
        <div v-if="isLoading" class="container max-w-[1024px] w-full px-[20px] flex justify-center items-center flex-col relative">
            <div @click="goToConfirmationPage()" class="flex cursor-pointer w-full justify-center items-center flex-col">
                <div class="w-full my-[10px] hidden relative border-[0.5px] border-gray-500 md:flex justify-center items-center p-[20px] rounded-[5px]">
                    <div class="w-[40px] h-[40px] shimmer border flex justify-center relative bg-gray-200 items-center overflow-x-hidden"></div>
                    <div class="w-[40%] mx-[20px]">
                        <h1 class="text-gray-700 shimmer w-[100px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                        <p class="text-xs w-[50px] shimmer h-[20px] mt-[5px] bg-gray-200 text-gray-500"></p>
                    </div>
                    <div class="w-[10%]">
                        <h1 class="text-gray-700 shimmer w-[80px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                        <p class="text-xs w-[60px] shimmer mt-[5px] h-[20px] bg-gray-200 text-gray-500"></p>   
                    </div>
                    <div class="w-[10%] mx-[20px]">
                        <h1 class="text-gray-700 shimmer w-[80px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                        <p class="text-xs w-[100px] shimmer h-[20px] mt-[5px] bg-gray-200 text-gray-500"></p>
                    </div>
                    <div class="w-[10%]">
                        <h1 class="text-gray-700 shimmer w-[100px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                        <p class="text-xs w-[100px] shimmer h-[20px] mt-[5px] bg-gray-200 text-gray-500"></p>
                    </div>
                    <div class="w-[15%] ml-[20px]">
                        <h1 class="text-green-700 shimmer w-[100px] h-[30px] bg-gray-200 font-bold text-center"></h1>
                    </div>
                </div>
                <div class="w-full my-[10px] md:hidden relative border-[0.5px] border-gray-500 flex flex-col justify-center items-center p-[20px] rounded-[5px]">
                    <div class="w-full flex justify-between items-start">
                        <div class="flex justify-center items-center">
                            <div class="flex flex-col justify-center items-start">
                                <h1 class="text-gray-700 shimmer w-[40px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                                <p class="text-xs w-[30px] shimmer mt-[5px] h-[20px] bg-gray-200 text-gray-500"></p>
                            </div>
                            <div id="flech" class="w-[50px] shimmer mx-[5px] h-[2px] bg-gray-200 relative top-[-6px]"></div>
                            
                            <div class="flex flex-col justify-center items-start">
                                <h1 class="text-gray-700 shimmer w-[40px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                                <p class="text-xs w-[30px] shimmer mt-[5px] h-[20px] bg-gray-200 text-gray-500"></p>
                            </div>
                        </div>
                        <h1 class="text-green-700 w-[100px] shimmer h-[25px] bg-gray-200 font-bold text-center"></h1>
                    </div>
                    <div class="w-full flex mt-[5px] justify-start items-center">
                        <div class="w-[50px] h-[50px] shimmer bg-gray-200 border flex justify-center relative items-center overflow-x-hidden"></div>
                        <div class="mx-[5px]">
                            <h1 class="text-gray-700 shimmer w-[150px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                            <p class="text-xs w-[200px] shimmer h-[25px] mt-[5px] bg-gray-200 text-gray-500"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="goToConfirmationPage()" class="flex cursor-pointer w-full justify-center items-center flex-col">
                <div class="w-full my-[10px] hidden relative border-[0.5px] border-gray-500 md:flex justify-center items-center p-[20px] rounded-[5px]">
                    <div class="w-[40px] h-[40px] shimmer border flex justify-center relative bg-gray-200 items-center overflow-x-hidden"></div>
                    <div class="w-[40%] mx-[20px]">
                        <h1 class="text-gray-700 shimmer w-[100px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                        <p class="text-xs w-[50px] shimmer h-[20px] mt-[5px] bg-gray-200 text-gray-500"></p>
                    </div>
                    <div class="w-[10%]">
                        <h1 class="text-gray-700 shimmer w-[80px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                        <p class="text-xs w-[60px] shimmer mt-[5px] h-[20px] bg-gray-200 text-gray-500"></p>   
                    </div>
                    <div class="w-[10%] mx-[20px]">
                        <h1 class="text-gray-700 shimmer w-[80px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                        <p class="text-xs w-[100px] shimmer h-[20px] mt-[5px] bg-gray-200 text-gray-500"></p>
                    </div>
                    <div class="w-[10%]">
                        <h1 class="text-gray-700 shimmer w-[100px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                        <p class="text-xs w-[100px] shimmer h-[20px] mt-[5px] bg-gray-200 text-gray-500"></p>
                    </div>
                    <div class="w-[15%] ml-[20px]">
                        <h1 class="text-green-700 shimmer w-[100px] h-[30px] bg-gray-200 font-bold text-center"></h1>
                    </div>
                </div>
                <div class="w-full my-[10px] md:hidden relative border-[0.5px] border-gray-500 flex flex-col justify-center items-center p-[20px] rounded-[5px]">
                    <div class="w-full flex justify-between items-start">
                        <div class="flex justify-center items-center">
                            <div class="flex flex-col justify-center items-start">
                                <h1 class="text-gray-700 shimmer w-[40px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                                <p class="text-xs w-[30px] shimmer mt-[5px] h-[20px] bg-gray-200 text-gray-500"></p>
                            </div>
                            <div id="flech" class="w-[50px] shimmer mx-[5px] h-[2px] bg-gray-200 relative top-[-6px]"></div>
                            
                            <div class="flex flex-col justify-center items-start">
                                <h1 class="text-gray-700 shimmer w-[40px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                                <p class="text-xs w-[30px] shimmer mt-[5px] h-[20px] bg-gray-200 text-gray-500"></p>
                            </div>
                        </div>
                        <h1 class="text-green-700 w-[100px] shimmer h-[25px] bg-gray-200 font-bold text-center"></h1>
                    </div>
                    <div class="w-full flex mt-[5px] justify-start items-center">
                        <div class="w-[50px] h-[50px] shimmer bg-gray-200 border flex justify-center relative items-center overflow-x-hidden"></div>
                        <div class="mx-[5px]">
                            <h1 class="text-gray-700 shimmer w-[150px] h-[25px] bg-gray-200 font-bold text-sm"></h1>
                            <p class="text-xs w-[200px] shimmer h-[25px] mt-[5px] bg-gray-200 text-gray-500"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isLoading" class="container max-w-[1024px] w-full px-[20px] flex justify-center items-center flex-col relative">
            <h1 class="text-gray-700 w-full text-left font-bold text-2xl">Meilleurs trajets</h1>
            <p class="text-xs text-gray-500 w-full text-left ">
                Classés avec soin pour vous. Les prix comprennent les taxes et frais obligatoires pour 1 adulte. Des frais facultatifs et des frais de bagages peuvent s'appliquer. 
            </p>
            <router-link v-for="(trajet, index) in filteredTrajets" :key="index" class="flex cursor-pointer w-full justify-center mt-[20px] items-center flex-col" :to="'/confirmation/reservation/'+ trajet._id">
                <div class="w-full my-[10px] hidden relative border-[0.5px] border-gray-500 md:flex justify-center items-center p-[20px] rounded-[5px]">
                    <div class="w-[40px] h-[40px] border flex justify-center relative items-center overflow-x-hidden">
                        <img v-if="trajet.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                        <img v-if="trajet.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                        <img v-if="trajet.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                        <img v-if="trajet.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                    </div>
                    <div class="w-[40%] mx-[20px]">
                        <h1 class="text-gray-700 font-bold text-sm">{{ trajet.heureDepart }} - {{ getHeureDestination(trajet.heureDepart, trajet.duree) }}</h1>
                        <p class="text-xs text-gray-500">{{ trajet.nomCompagnie }}</p>
                    </div>
                    <div class="w-[15%]">
                        <h1 class="text-gray-700 font-bold text-sm">{{ trajet.duree }} heures</h1>
                        <p class="text-xs text-gray-500">{{ trajet.depart }} - {{ trajet.destination }}</p>   
                    </div>
                    <div class="w-[10%] mx-[20px]">
                        <h1 class="text-gray-700 font-bold text-sm"><span v-if="trajet.escale.length === 0">0</span> <span v-else>{{ trajet.escale.length }}</span> escale <i class="fas fa-circle-info text-orange-500 text-xs mx-[5px]"></i></h1>
                        <p class="text-xs text-gray-500"><span v-if="trajet.escale.length === 0">Pas d'escale</span> <span v-else>{{ trajet.escale[0].heure }}</span></p>
                    </div>
                    <div class="w-[10%]">
                        <h1 class="text-gray-700 font-bold text-sm">5 kg CO2e</h1>
                        <p class="text-xs text-gray-500">Emission est.</p>
                    </div>
                    <div class="w-[15%] ml-[20px]">
                        <h1 class="text-green-700 font-bold text-center">{{ trajet.prix_reduit }} FCFA</h1>
                    </div>
                </div>
                <div class="w-full my-[10px] md:hidden relative border-[0.5px] border-gray-500 flex flex-col justify-center items-center p-[20px] rounded-[5px]">
                    <div class="w-full flex justify-between items-start">
                        <div class="flex justify-center items-center">
                            <div class="flex flex-col justify-center items-start">
                                <h1 class="text-gray-700 font-bold text-sm">{{ trajet.heureDepart }}</h1>
                                <p class="text-xs text-gray-500">{{ trajet.depart }}</p>
                            </div>
                            <div id="flech" class="w-[50px] mx-[5px] h-[2px] bg-gray-700 relative top-[-6px]"></div>
                            <div class="flex flex-col justify-center items-start">
                                <h1 class="text-gray-700 font-bold text-sm">{{ getHeureDestination(trajet.heureDepart, trajet.duree) }}</h1>
                                <p class="text-xs text-gray-500">{{ trajet.destination }}</p>
                            </div>
                        </div>
                        <h1 class="text-green-700 font-bold text-center">{{ trajet.prix_reduit }} FCFA</h1>
                    </div>
                    <div class="w-full flex mt-[5px] justify-start items-center">
                        <div class="w-[50px] h-[50px] border flex justify-center relative items-center overflow-x-hidden">
                            <img v-if="trajet.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                            <img v-if="trajet.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                            <img v-if="trajet.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                            <img v-if="trajet.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                        </div>
                        <div class="mx-[5px]">
                            <h1 class="text-gray-700 font-bold text-sm"><span v-if="trajet.escale.length === 0">0</span> <span v-else>{{ trajet.escale.length }}</span> escale - <span> {{ trajet.duree }} heures</span> </h1>
                            <p class="text-xs text-gray-500"><span>{{ trajet.nomCompagnie }} </span> - <span> 5 kg CO2e est. </span></p>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div v-if="filteredTrajets.length === 0" class="container max-w-[1024px] w-full px-[20px] py-[50px] flex justify-center items-center flex-col relative">
            <h1 class="text-gray-500 font-bold text-sm text-center">Aucun trajet disponible</h1>
        </div>
    </section>
    <FooterComponent/>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue"
import FooterComponent from "@/components/FooterComponent.vue"
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

const trajets = ref([]);
const isLoading = ref(false);
const error = ref(false);
const search = ref({
  depart: '',
  destination: '',
  type: '',
  passengers: 1,
  wifi: false,
  compagnie: '',
});

const fetchTrajets = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(`/api/trajets`);
    trajets.value = response.data;
  } catch (err) {
    error.value = true;
  } finally {
    isLoading.value = false;
  }
};

const filteredTrajets = computed(() => {
  return trajets.value.filter(trajet => {
    const matchesDepart = search.value.depart ? trajet.depart.toLowerCase().includes(search.value.depart.toLowerCase()) : true;
    const matchesDestination = search.value.destination ? trajet.destination.toLowerCase().includes(search.value.destination.toLowerCase()) : true;
    const matchesCompagnie = search.value.compagnie ? trajet.nomCompagnie.toLowerCase().includes(search.value.compagnie.toLowerCase()) : true;
    // Vous pouvez ajouter d'autres filtres ici en fonction des autres critères de recherche
    return matchesDepart && matchesDestination && matchesCompagnie;
  });
});

const getHeureDestination = (heureDepart, duree) => {
  if (!heureDepart || duree === undefined) return ''; // Vérifiez si les données sont disponibles

  const [hours, minutes] = heureDepart.split(':').map(Number);
  const departDate = new Date();
  departDate.setHours(hours, minutes, 0, 0);

  // Convertir la durée en minutes
  const dureeMinutes = duree * 60;

  // Ajouter la durée en minutes
  departDate.setMinutes(departDate.getMinutes() + dureeMinutes);

  const destinationHours = String(departDate.getHours()).padStart(2, '0');
  const destinationMinutes = String(departDate.getMinutes()).padStart(2, '0');

  return `${destinationHours}:${destinationMinutes}`;
};

// Initial fetch
onMounted( () => {
    fetchTrajets()
});

const recherche = ref(false)
const topp = ref(null)

onMounted(() => {
    window.addEventListener("scroll", handleScroll);
});

function handleScroll() {
    if (topp.value) {
        if (window.scrollY > 100) {
            recherche.value = true
        } else {
            recherche.value = false
        }
    }
}

const router = useRouter()

</script>

<style>

</style>