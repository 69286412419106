<template>
    <div class="flex flex-col z-[500] justify-center items-center w-[350px] max-w-[90%] left-[50%] translate-x-[-50%] fixed top-[80px] ">
        <div v-if="SuccessMessage" class="mt-[10px] z-[500] bg-green-600 shadow-4xl text-white flex flex-col justify-center items-center font-bold text-center p-[20px] w-full rounded-[10px]">
            {{SuccessMessage}} 
        </div>
        <div v-if="ErrorMessage" class="mt-[10px] z-[500] bg-red-600 shadow-4xl text-white flex flex-col justify-center items-center font-bold text-center p-[20px] w-full rounded-[10px]">
            {{ErrorMessage}}
        </div>
    </div>
    <section class="flex justify-center  items-center relative h-[400px] max-h-[400px]">
        <div class="w-full h-full relative">
            <img src="../assets/images/1628627 (1).jpg" alt="" class="w-full h-full object-cover absolute object-center">
        </div>
        <div class="w-full h-full absolute flex flex-col justify-center items-center z-10 bg-black/60">
            <HeaderComponent/>
            <div class="container max-w-[1024px] h-full w-full relative px-[25px] py-[50px]">
                <div class="w-full h-full flex flex-col justify-center items-center relative">
                    <h1 class="text-center max-w-[500px] text-white font-bold md:text-3xl text-2xl">Contact informations</h1>
                    <p class="text-gray-100 mt-[10px] text-center">
                        Nous oeuvrons pour être le plus proche de nos clients !
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="w-full relative flex justify-center items-center py-[50px] bg-gray-100">
        <div class="container max-w-[1024px] px-[20px] w-full relative flex justify-center items-center">
            <div class="w-full relative flex md:flex-row flex-col justify-center md:items-start items-center">
                <div class="md:w-[30%] w-full m-[10px] relative">
                    <div class="md:mx-[10px] my-[10px] w-full rounded-[10px] bg-white p-[10px] flex items-center">
                        <div class="w-[35px] min-w-[35px] h-[35px] rounded-[50%] flex justify-center items-center text-sm font-bold text-orange-600 bg-gray-100">
                            <i class="fa-solid fa-phone"></i>
                        </div>
                        <div class="flex flex-col ml-[10px]">
                            <h1 class="text-gray-800 text-sm font-bold">Numéro de téléphone</h1>
                            <p class="text-xs font-bold text-gray-400">+229 91559438</p>
                        </div>
                    </div>
                    <div class="md:mx-[10px] my-[10px] w-full rounded-[10px] bg-white p-[10px] flex items-center">
                        <div class="w-[35px] min-w-[35px] h-[35px] rounded-[50%] flex justify-center items-center text-sm font-bold text-orange-600 bg-gray-100">
                            <i class="fa-solid fa-envelope"></i>
                        </div>
                        <div class="flex flex-col ml-[10px]">
                            <h1 class="text-gray-800 text-sm font-bold">Adresse Email</h1>
                            <p class="text-xs font-bold text-gray-400">contact@bet-booking.com</p>
                        </div>
                    </div>
                    <div class="md:mx-[10px] my-[10px] w-full rounded-[10px] bg-white p-[10px] flex items-center">
                        <div class="w-[35px] min-w-[35px] h-[35px] rounded-[50%] flex justify-center items-center text-sm font-bold text-orange-600 bg-gray-100">
                            <i class="fa-solid fa-business-time"></i>
                        </div>
                        <div class="flex flex-col ml-[10px]">
                            <h1 class="text-gray-800 text-sm font-bold">Horaires</h1>
                            <p class="text-xs font-bold text-gray-400">08h à 18h</p>
                        </div>
                    </div>
                    <div class="md:mx-[10px] my-[10px] w-full rounded-[10px] bg-white p-[10px] flex items-center">
                        <div class="w-[35px] min-w-[35px] h-[35px] rounded-[50%] flex justify-center items-center text-sm font-bold text-orange-600 bg-gray-100">
                            <i class="fa-solid fa-location-dot"></i>
                        </div>
                        <div class="flex flex-col ml-[10px]">
                            <h1 class="text-gray-800 text-sm font-bold">Localisation</h1>
                            <p class="text-xs font-bold text-gray-400">Abomey-calavi</p>
                        </div>
                    </div>
                </div>
                <div class="md:w-[70%] w-full m-[10px] relative">
                    <div class="w-full rounded-[10px] bg-white p-[10px] md:mx-[10px] my-[10px]">
                        <h1 class="text-gray-800 font-bold">Envoyez un Message</h1>
                        <p class="text-sm text-gray-400 my-[10px]">
                            Laissez nous un message dès maintenant et nous vous reviendrons sous peu. Merci !
                        </p>
                        <form @submit.prevent="sendMessage" class="w-full relative">
                            <div class="w-full relative flex justify-between items-center flex-col md:flex-row">
                                <div class="md:w-[48%] my-[10px] relative w-full">
                                    <input type="text" v-model="nom" id="nom" :class="{'not-empty border-green-600': nom.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                                    <label for="nom" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Nom <span class="text-red-600">*</span></label>
                                </div>
                                <div class="md:w-[48%] my-[10px] relative w-full">
                                    <input type="text" v-model="prenoms" id="prenoms" :class="{'not-empty border-green-600': prenoms.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                                    <label for="prenoms" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Prenoms <span class="text-red-600">*</span></label>
                                </div>
                            </div>
                            <div class="w-full relative flex justify-between items-center flex-col md:flex-row">
                                <div class="md:w-[48%] my-[10px] relative w-full">
                                    <input type="text" v-model="email" id="email" :class="{'not-empty border-green-600': email.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                                    <label for="email" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Email</label>
                                </div>
                                <div class="md:w-[48%] my-[10px] relative w-full">
                                    <input type="text" v-model="phone" id="phone" :class="{'not-empty border-green-600': phone.trim() !== ''}" class="w-full p-[10px] focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm">
                                    <label for="phone" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Phone <span class="text-red-600">*</span></label>
                                </div>
                            </div>
                            <div class="w-full relative my-[10px]">
                                <textarea type="text" v-model="message" id="message" :class="{'not-empty border-green-600': message.trim() !== ''}" class="w-full p-[10px] h-[120px] resize-none focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm"></textarea>
                                <label for="message" class="text-sm font-bold text-gray-800 absolute top-[10px] left-[10px] label">Message <span class="text-red-600">*</span></label>
                            </div>
                            <div class="w-full relative my-[10px]">
                                <button type="submit" class="text-sm font-bold text-white orange-600 p-[10px] rounded-[5px] bg-orange-600">Envoyez</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterComponent/>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue"
import FooterComponent from "@/components/FooterComponent.vue"
import { ref, onMounted } from 'vue'
import axios from 'axios'

const nom = ref('')
const prenoms = ref('')
const email = ref('')
const phone = ref('')
const message = ref('')

const ErrorMessage = ref('')
const SuccessMessage = ref('')
const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      SuccessMessage.value = ''; 
    }, 3000);
};
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      ErrorMessage.value = ''; 
    }, 3000);
};

const sendMessage = async () => {
  try {
    const response = await axios.post('/api/messages', { nom: nom.value, prenoms: prenoms.value, email: email.value, numero: phone.value, message: message.value });
    SuccessMessage.value = "Message envoyé avec succès";
    hideSuccessMessageAfterDelay()
  } catch (error) {
    ErrorMessage.value = 'Erreur lors de l\'envoie du message';
    hideErrorMessageAfterDelay()
    
  }
};
</script>

<style>
.label {
    transition: 0.2s;
}
.input-text:focus ~ .label {
    position: absolute;
    top: -10px;
    background: white;
    padding: 0px 5px;
    font-size: 12px;
}
.not-empty ~ .label {
    position: absolute;
    top: -10px;
    background: white;
    padding: 0px 5px;
    font-size: 12px;
}
</style>