<template>
    <header ref="myheader"  class="flex w-full fixed top-0 h-[80px] z-50 duration-300 justify-center items-center">
        <div class="container max-w-[1024px] flex justify-between px-[20px] items-center">
            <div class="flex w-full lg:w-auto justify-between items-center">
                <img src="../assets/logo.png" alt="" class="w-[125px]">
                <span class="text-3xl text-white cursor-pointer mx-2 lg:hidden flex" @click="toggleMenu()">
                    <div><i class="fa-solid fa-bars ouvrir" :class="{'hidden': isMenuOpen}"></i></div>
                    <div><i class="fa-solid fa-xmark fermer" :class="{'hidden': !isMenuOpen}"></i></div>
                </span>
            </div>
            <div :class="{
                  'top-[80px]': isMenuOpen,
                  'opacity-100': isMenuOpen,
                  'opacity-0': !isMenuOpen,
                  'scale-0 lg:scale-100 lg:translate-y-0': !isMenuOpen
                }" class="w-screen lg:w-auto duration-300 ease-linear h-[calc(100vh-80px)] top-[80px] lg:opacity-100 lg:h-auto z-50 bg-gray-800 lg:bg-transparent fixed lg:relative lg:top-0 left-0 right-0 bottom-0 flex justify-center items-center">
                <nav class="lg:p-[10px] relative flex justify-between items-center flex-col p-[50px] z-[100] w-[calc(100vw-80px)] lg:w-auto h-[calc(100vh-150px)] lg:h-auto rounded-[5px] md:border border-gray-300">
                    <ul class="flex w-full lg:w-auto flex-col lg:flex-row justify-center items-center text-gray-300">
                        <li @click="goToHomePage()"
                        :class="{'bg-gray-100/25 text-white w-full md:w-auto text-center rounded-[5px]': $route.path === '/'}"
                        class="mx-[5px] my-[10px] lg:my-0 p-[5px] px-[10px] cursor-pointer md:text-sm text-xl font-semibold">Accueil</li>
                        <li @click="goToReservationPage()"
                        :class="{'bg-gray-100/25 text-white w-full md:w-auto text-center rounded-[5px]': $route.path === '/reservation'}"
                        class="mx-[5px] my-[10px] lg:my-0 p-[5px] px-[10px] cursor-pointer md:text-sm text-xl font-semibold">Réservation</li>
                        <li @click="goToAboutPage()"
                        :class="{'bg-gray-100/25 text-white w-full md:w-auto text-center rounded-[5px]': $route.path === '/about'}"
                        class="mx-[5px] my-[10px] lg:my-0 p-[5px] px-[10px] cursor-pointer md:text-sm text-xl font-semibold">A propos</li>
                        <li @click="goToContactPage()"
                        :class="{'bg-gray-100/25 text-white w-full md:w-auto text-center rounded-[5px]': $route.path === '/contact'}"
                        class="mx-[5px] my-[10px] lg:my-0 p-[5px] px-[10px] cursor-pointer md:text-sm text-xl font-semibold">Contact</li>
                    </ul>
                    <button class="text-sm absolute flex justify-center items-center w-[calc(100%-40px)] bottom-[20px] text-center lg:hidden font-bold text-gray-300 border border-gray-300 hover:text-white hover:bg-gray-100/25 duration-200 rounded-[5px] py-[10px]">Se connecter</button>
                </nav>
            </div>
            <button class="text-sm hidden lg:flex font-bold text-gray-300 border border-gray-300 hover:text-white hover:bg-gray-100/25 duration-200 rounded-[5px] py-[10px] px-[20px]">Se connecter</button>
        </div>
    </header>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const isMenuOpen = ref(false);

const myheader = ref(null);

const router = useRouter();

onMounted(() => {
    window.addEventListener("scroll", handleScroll);
});

function goToHomePage() {
    router.push("/")
}
function goToReservationPage() {
    router.push("/reservation")
}
function goToPartenairsPage() {
    router.push("/partenairs")
}
function goToContactPage() {
    router.push("/contact")
}
function goToAboutPage() {
    router.push("/about")
}

/* function goToHomePage() {
    router.push("/")
} */


const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value;
    myheader.value.classList.add("stick");
}

function handleScroll() {
    if (myheader.value) {
        if (window.scrollY > 0) {
            myheader.value.classList.add("stick");
        } else {
            myheader.value.classList.remove("stick");
        }
    }
}
</script>

<style>

</style>