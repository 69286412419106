<template>
    <router-view/>
</template>

<style>
#coffee {
    font-family: 'Coffee Mood', sans-serif;
}
#aero {
    font-family: 'Aero Matics Display', sans-serif;                                          
}
.stick {
    background-color: rgb(31, 41, 55);
    height: 90px;
}
.container {
    max-width: 1024px;
}
.degradee1 {
    background: linear-gradient(to top right, #1e44b8, #2f45bd, #554bca, #9656e1);
}
.clamp4 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.parent::-webkit-scrollbar {
    width: 0%;
    height: 0%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmer {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.2) 75%);
  background-size: 2000px 100%;
  animation: shimmer 1.5s infinite;
}
</style>
